import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: "ap-south-1:7f20cdac-73e5-4fa6-9a02-21f39d06f08f",
    region: "ap-south-1",
    mandatorySignIn: false,
    userPoolWebClientId: "20ctkv99u8ass8ioef45cv4m5i",
    Cognito: {
      userPoolId: "ap-south-1_qYYwqxObk",
      userPoolClientId: "20ctkv99u8ass8ioef45cv4m5i"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <App />
  </Router>
);