import React from 'react';
import './secondary-header-styles.css';
import Logo from '../../Assets/Logos/HOPE_website_logo.jpg';
import { Link } from 'react-router-dom';

function SecondaryHeader() {
    return (
        <header style={{ zIndex: 10 }}>
            <Link to="/">
                <div className='logo-container'>
                    <img className='logo-img' src={Logo} />
                </div>
            </Link>
        </header>
    );
}

export default SecondaryHeader;