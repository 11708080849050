import React from 'react';
import './WarningModalStyle.css';

const WarningModal = ({ isOpen, errorMessage, onClose }) => {
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <h2 className='error_message_text' >{errorMessage}</h2>
                <button className='Error_button'
                    onClick={(event) => {
                        event.preventDefault();
                        onClose();
                    }}>OK</button>
            </div>
        </div>
    );
};


export default WarningModal;
