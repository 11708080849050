import React from 'react'
import '../CommonButton/CommonButtonStyle.css';

const CommonButton = (props) => {

    const handleOnPress = (() => {
        if (props.onPress) {
            props.onPress();
        } else {
            console.log("default function");
        }
    })

    return (
        <button className='Common_btn_Text' onClick={handleOnPress}>
            {props.buttonText ? props.buttonText : "Download App"}
        </button>
    )
}

export default CommonButton;