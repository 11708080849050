import React from 'react'
import './loaderStyle.css'

const loader = () => {
    return (
        <div className="loader-container">
            <div className="wrapper">

                <div className="loader-spinner"></div>
                <h1 className='LoadingText'>Loading</h1>
            </div>
        </div>
    )
}

export default loader