import React, { useState, useEffect } from "react";
import './privacy-policy-styles.css';
import SecondaryHeader from "../../Components/SecondaryHeader.js";
import HOPELogo from '../../Assets/Logos/HOPE Logo.png';
import HOPEBuddyLogo from '../../Assets/Logos/HOPE Buddy Logo.png';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import HOPEPP from '../../Assets/Documents/HOPE-APP-Privacy-Policy.pdf';
import HOPEBuddyPP from '../../Assets/Documents/HOPE-Buddy-APP-Privacy-Policy.pdf';

const PrivacyPolicy = () => {

    const [isHopeActive, setIsHopeActive] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
        const hopebuddy = urlParams.get('hopebuddy');
        if (hopebuddy) {
            setIsHopeActive(false);
        }
    }, []);

    return (
        <div>
            <SecondaryHeader />
            <div className="logo-container1">
                <div onClick={() => setIsHopeActive(true)} className={isHopeActive ? "logoo-container cur-point" : "cur-point"}>
                    <img className={isHopeActive ? "logo-btn1-active" : "logo-btn1-inactive"} src={HOPELogo} />
                </div>
                <div onClick={() => setIsHopeActive(false)} className={isHopeActive ? "cur-point" : "logoo-container1 cur-point"}>
                    <img className={isHopeActive ? "logo-btn1-inactive" : "logo-btn1-active"} src={HOPEBuddyLogo} />
                </div>
            </div>
            <div className="pdf-container">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer fileUrl={isHopeActive ? HOPEPP : HOPEBuddyPP}></Viewer>
                </Worker>
            </div>
        </div>
    )
}

export default PrivacyPolicy;