import React, { useState } from 'react';
import './HeaderStyle.css';
import Navbar from '../NavbarModal/NavBar';
import Hamburger from '../../Assets/Mobile_Assets/hamburger.png';
import Logo from '../../Assets/Logos/HOPE_website_logo.jpg';

const Header = () => {
  const NavbarData = [
    {
      id: 1,
      name: "About",
      hashLinkName: "About",
      hashLink: "About"
    },
    {
      id: 2,
      name: "Services",
      hashLinkName: "Services",
      hashLink: "Services"
    },
    {
      id: 3,
      name: "Solution",
      hashLinkName: "Solution",
      hashLink: "Solution"
    },
    {
      id: 4,
      name: "Pricing",
      hashLinkName: "Pricing",
      hashLink: "Pricing"
    },
    {
      id: 5,
      name: "Contact us",
      hashLinkName: "Contact",
      hashLink: "Contact"
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const scrollToSectionWithOffset = (sectionId, offset = 0) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      const offsetTop = targetElement.offsetTop;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: 'smooth',
      });
    }
  };


  return (
    <div className='Header_container'>
      <div>
        <img className='header-logo' src={Logo} />
      </div>
      <Navbar
        isOpen={isModalOpen}
        onClose={toggleModal}
        items={NavbarData}
      />
      <ul className='Navbar_List'>
        {NavbarData.map((item) => (
          <li key={item.id}>
            <a
              onClick={() => scrollToSectionWithOffset(item.hashLinkName, 100)}>
              {item.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="MobileOnly">
        <img onClick={toggleModal} src={Hamburger} className='Hamburger' alt="Hamburger" />
      </div>
    </div>
  );
}

export default Header;
