import React from 'react'
import './dashboard_content_style.css'

const Dashboard_content = (props) => {
    const storedData = sessionStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    let data = props.dashboard_data;

    const handleNurseClick = (isInnerDataclicked, title) => {
        if (isInnerDataclicked && title === "Total") {
            props.onItemSelect(0);
        } else {
            if (isInnerDataclicked) {
                props.statusHandle(title);
            }
            props.onItemSelect(1);
        }
    };

    const dashboard_table_headers = [
        {
            id: 0,
            title: "Type",
        },
        {
            id: 1,
            title: "Pending",
        }, {
            id: 2,
            title: "Correction",

        }, {
            id: 3,
            title: "Blocked",
        }, {
            id: 4,
            title: "Active",
        },
        {
            id: 5,
            title: "Resent",
        }, {
            id: 6,
            title: "Terminated",
        }, {
            id: 7,
            title: "Rejected",
        }, {
            id: 8,
            title: "Total",
        }
    ]

    const dashboardData = [
        {
            id: 0,
            title: "Pending",
            TotalNumber: data.pending
        }, {
            id: 1,
            title: "Pending with correction",
            TotalNumber: data.correction
        }, {
            id: 2,
            title: "Blocked",
            TotalNumber: data.blocked
        }, {
            id: 4,
            title: "Active",
            TotalNumber: data.active
        },
        {
            id: 5,
            title: "Resent",
            TotalNumber: data.resent
        }, {
            id: 6,
            title: "Terminated",
            TotalNumber: data.terminated
        }, {
            id: 7,
            title: "Rejected",
            TotalNumber: data.rejected
        }, {
            id: 8,
            title: "Total",
            TotalNumber: data.total
        }
    ]

    return (
        <div className="Dashboard_content_container">
            <div className="main-content-header">
                <h1>Dashboard</h1>
            </div>
            <table>
                <thead>
                    <tr>
                        {dashboard_table_headers.map((item) => (
                            <th key={item.id}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <button className='type-common-class'>
                                Nurse
                            </button>
                        </td>
                        {dashboardData.map((item) => (
                            <td key={item.id}>
                                <button className='button-common-class' style={item.title == "Total" ? { cursor: "default" } : { cursor: 'pointer' }} onClick={() => handleNurseClick(true, item.title)} >
                                    {item.TotalNumber}
                                </button>
                            </td>
                        )
                        )}
                    </tr>
                    {/* <tr>
                        <td>
                            <button className='button-common-class' >
                                Doctor
                            </button>
                        </td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default Dashboard_content;
