import React from 'react';
import '../Pricing/PricingStyle.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Pricing = () => {
    const PricingTabContent = [
        {
            id: 1,
            title: "Patient",
            content: [{
                id: 1,
                title: "Basic Plan",
                header: "30 days Free Trial",
                features: [
                    "Appointment Scheduling (Dr, Nurse, Nutrionist, Physiotherapist, etc…)",
                    "Medicine order",
                    "Lab order",
                    "Timelines Access",
                    "Record Vitals",
                    "Connect and share medical info with Care Circle via HOPE app only",
                    "Secure Storing of medical info",
                    "Equipment rental",
                    "Ambulance Hire"
                ],
                subscripiton: [
                    "99 per month",
                    "249 for 3 months",
                    "449 for 6 months"
                ]
            },
            {
                id: 2,
                title: "Basic+ Plan",
                header: "30 days Free Trial",
                features: [
                    "Appointment Scheduling (Dr, Nurse, Nutrionist, Physiotherapist, etc…)",
                    "Medicine order",
                    "Lab order",
                    "Timelines Access",
                    "Record Vitals",
                    "Connect and share medical info with Care Circle via HOPE app only",
                    "Secure Storing of medical info",
                    "Equipment rental",
                    "Ambulance Hire"
                ],
                subscripiton: [
                    "199 per month+Incentive",
                    "549 for 3 months+Incentive",
                    "999 for 6 months+Incentive"
                ]
            },
            {
                id: 3,
                title: "Premium Plan",
                header: "30 days Free Trial",
                features: [
                    "All Basic Features",
                    "Q/A with Service Provider",
                    "Connect with other cancer patients",
                    "Post a query in Forum",
                    "Read an entire article",
                    "Share medical info (including documents) with Non-HOPE people",
                    "AI Chatbox",
                ],
                subscripiton: [
                    "199 per month",
                    "549 for 3 months",
                    "999 for 6 months"
                ]
            },
            {
                id: 4,
                title: "Premium+ Plan",
                header: "30 days Free Trial",
                features: [
                    "All Basic Features",
                    "Q/A with Service Provider",
                    "Connect with other cancer patients",
                    "Post a query in Forum",
                    "Read an entire article",
                    "Share medical info (including documents) with Non-HOPE people",
                    "AI Chatbox",
                ],
                subscripiton: [
                    "299 per month+Incentive",
                    "849 for 3 months+Incentive",
                    "1599 for 6 months+Incentive"
                ]
            },
            ]
        },
        {
            id: 2,
            title: "Nurse",
            content: [{
                id: 1,
                title: "Basic Plan",
                header: "30 days Free Trial",
                features: [
                    "Appointment Scheduling",
                    "Schedule Management",
                    "Connect with Patient and their loved ones",
                    "Create and Manage tasks",
                    "Secure Access to Patient medical data",
                    "Billing and Payment",
                ],
                subscripiton: [
                    "199 per month",
                    "549 for 3 months",
                    "999 for 6 months"
                ]
            },
            {
                id: 2,
                title: "Premium Plan",
                header: "30 days Free Trial",
                features: [
                    "All Basic Features",
                    "Access to AI Chatbox",
                    "Referral to other Medical Service Providers",
                ],
                subscripiton: [
                    "299 per month",
                    "799 for 3 months",
                    "1499 for 6 months"
                ]
            }
            ]
        },
        {
            id: 3,
            title: "Nutritionst",
            content: [{
                id: 1,
                title: "Basic Plan",
                header: "30 days Free Trial",
                features: [
                    "Appointment Scheduling",
                    "Schedule Management",
                    "Connect with Patient and their loved ones",
                    "Create and Manage meal plan",
                    "Create reminders/notifications Secure Access to Patient medical data",
                    "Monitor Patient",
                    "Billing and Payment",
                ],
                subscripiton: [
                    "199 per month",
                    "549 for 3 months",
                    "999 for 6 months"
                ]
            },
            {
                id: 2,
                title: "Premium Plan",
                header: "30 days Free Trial",
                features: [
                    "All Basic Features",
                    "Access to AI Chatbox",
                    "Create Articles",
                    "Referral to other Medical Service Providers",
                ],
                subscripiton: [
                    "299 per month",
                    "799 for 3 months",
                    "1499 for 6 months"
                ]
            },
            ]
        },
        {
            id: 4,
            title: "Doctor",
            content: [{
                id: 1,
                title: "Basic Plan",
                header: "30 days Free Trial",
                features: [
                    "Appointment Scheduling",
                    "Time Management",
                    "Connect with Patient and their loved ones",
                    "Create and Manage treatment plan",
                    "Create reminders/notifications",
                    "Secure Access to Patient medical data",
                    "Monitor Patient",
                    "Billing and Payment",
                ],
                subscripiton: [
                    "399 per month",
                    "1099 for 3 months",
                    "2099 for 6 months"
                ]
            },
            {
                id: 2,
                title: "Premium Plan",
                header: "30 days Free Trial",
                features: [
                    "All Basic Features",
                    "Access to AI Chatbox",
                    "Create Articles",
                    "Referral to other Medical Service Providers",
                ],
                subscripiton: [
                    "499 per month",
                    "1399 for 3 months",
                    "2699 for 6 months"
                ]
            }
            ]
        },
    ];

    return (
        <div className='Pricing_Screen_Container'>
            <div className="Pricing_inner_container">
                <div className="Pricing_header_Title">
                    <h1>PRICING</h1>
                </div>
                <Tabs className="pricing_tabs_container">
                    <TabList className="pricing_tablist" >
                        {PricingTabContent.map((tab) => (
                            <Tab className="pricing_tab_btn_container" key={tab.id} >{tab.title}</Tab>
                        ))}
                    </TabList>

                    {PricingTabContent.map((item) => (
                        <TabPanel key={item.id}>
                            <div className="pricing_TabPanel_container">
                                <h1>Subscription for {item.title}</h1>
                                <div className="pricing_list_container">
                                    {item.content.map((element, elementIndex) => (
                                        <div className="pricing_card_container" key={elementIndex}>
                                            <div className="_pricing_card_header_container">
                                                <div className="pricing_card_header">
                                                    <h1 key={elementIndex}>{element.title}</h1>
                                                </div>
                                            </div>
                                            <div className="pricing_card_content_container">
                                                <div className="content_inner_header">
                                                    <h1>{element.header}</h1>
                                                </div>
                                                <div className="pricing_features_container">
                                                    <div className="features_header_container">
                                                        <h1>Features</h1>
                                                    </div>
                                                    <ul className={item.id === 2 ? 'custom_list_below_features' : 'list_below_features'}>
                                                        {element.features.map((value, index) => (
                                                            <li key={index}>{value}</li>
                                                        ))}
                                                    </ul>
                                                    <div className="subscription_header_container">
                                                        <h1>Subscription Prices</h1>
                                                        <h1>(After Free trail)</h1>
                                                    </div>
                                                    <ul>
                                                        {element.subscripiton.map((value, index) => (
                                                            <li key={index}>{value}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </div>
    )
}

export default Pricing;