import React from 'react';
import './MissionScreenStyle.css';
import mission_banner_img from '../../Assets/WebSite_Assets/Web-Map.png';

const MissionScreen = () => {
    return (
        <div className="Mission_Screen_Container">
            <div className="inner_mission_container">
                <div className="mission_header_Title">
                    <h1>Mission & Vision</h1>
                </div>
                <div className="mission_text_below_header">
                    <h1>"To create an integrated health services platform for patients, their loved ones and medical services providers around the world.
                        We aim to enable efficient management of a patient's journey and to provide them with the best care at the comfort of their home."</h1>
                </div>
                <div className="mission_text_below_header_mobile">
                    <h1>"To create an integrated health services platform for patients, their loved ones and medical services providers around the world.</h1>
                </div>
                <div className="mission_text_below_header_mobile">
                    <h1>We aim to enable efficient management of a patient's journey and to provide them with the best care at the comfort of their home."</h1>
                </div>
                <div className="mission_banner_container">
                    <img src={mission_banner_img} alt="mission_banner_img" />
                </div>
            </div>
        </div>
    )
}

export default MissionScreen;