import React from 'react'
import '../ActivityLog/ActivityLog.css'

const ActivityLog = ({ activityLogs }) => {
    return (
        <div className="activity-log">
            <h1 className='Activity_Log_Header'>Activity Log</h1>
            <table className="activity-table">
                <thead style={{ display: 'flex', justifyContent: 'space-between', borderTop: "1px solid var(--Line-color)", borderBottom: "1px solid var(--Line-color)" }}>
                    <tr>
                        <th className="date" style={{ padding: "20px 49px", border: "0px" }}>Date</th>
                        <th className="time" style={{ padding: "20px 49px", border: "0px" }}>Time</th>
                        <th className="action" style={{ padding: "20px 49px", border: "0px" }}>Action</th>
                    </tr>
                    <tr>
                        <th style={{ padding: "20px 43px", border: "0px" }}>Comments</th>
                    </tr>
                </thead>
                {
                    activityLogs.length == 0 ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Nunito", marginTop: "25px", fontSize: "20px", fontStyle: "normal", fontWeight: "600" }}>
                            No Data Found
                        </div>
                        :
                        <tbody style={{ marginTop: "20px" }}>
                            {activityLogs.map((log, index) => (
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                    <tr key={index} style={{ padding: "0px", margin: "0px" }}>
                                        <td className="date" style={{ padding: "0px 0px", border: "0px", width: "127px", }}>{log.date}</td>
                                        <td className="time" style={{ padding: "0px 0px", border: "0px", width: "127px", }}>{log.time}</td>
                                        <td className="action" style={{ margin: "0px 0px", border: "0px", padding: "0px", width: "127px", }}>{log.action}</td>
                                    </tr>
                                    <tr>
                                        <td className="comments" style={{ border: "0px", width: "150px", padding: "0px" }}>{log.comments}</td>
                                    </tr>
                                </div>
                            ))}
                        </tbody>
                }
            </table>
        </div>
    )
}

export default ActivityLog