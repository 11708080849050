import React, { useState, useEffect } from 'react';
import './LandingPageStyles.css';
import Header from '../../Components/Header/Header';
import Banner from '../../Components/Banner';
import YourJourneySection from '../../Components/YourJourneySection/YourJourneySection';
import SolutionSection from '../../Components/SolutionSection/SolutionSection';
import HopeAppServices from '../../Components/HopeAppServices/HopeAppServices';
import AppInterfaceSection from '../../Components/AppInterfaceSection/AppInterfaceSection';
import MissionScreen from '../../Components/MissionScreen/MissionScreen';
import ContactUs from '../../Components/ContactUs/ContactUs';
import KeyFeatures from '../../Components/KeyFeatures/KeyFeatures';
import Pricing from '../../Components/Pricing/Pricing';
import Footer from '../../Components/Footer/Footer';

const LandingPage = () => {

    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const threshold = 200;
            setShowScrollButton(scrollY > threshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const svgArrow = `  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 2L3.293 10.707a1 1 0 0 0 1.414 1.414L11 5.414V20a1 1 0 0 0 2 0V5.414l6.293 6.293a1 1 0 0 0 1.414-1.414L12 2z"/>
  </svg>
  `;

    return (
        <div className="main_container">
            <div className='container'>
                <Header />
                <div className="Container_below_navbar">
                    <div className="screen_container">
                        <Banner />
                    </div>
                    {showScrollButton && (
                        <button className="scroll-up-button" onClick={scrollToTop}>
                            <div dangerouslySetInnerHTML={{ __html: svgArrow }} />
                        </button>
                    )}
                    <div className="screen_container" id="About">
                        <YourJourneySection />
                    </div>
                    <div className="screen_container" id="Solution" >
                        <SolutionSection />
                    </div>
                    <div className="screen_container" id="Services">
                        <HopeAppServices />
                    </div>
                    <div className="screen_container">
                        <KeyFeatures />
                    </div>
                    <div className="screen_container">
                        <AppInterfaceSection />
                    </div>
                    <div className="screen_container" id="Pricing">
                        <Pricing />
                    </div>
                    <div className="screen_container">
                        <MissionScreen />
                    </div>
                    <div className="screen_container" id="Contact">
                        <ContactUs />
                    </div>
                </div>
            </div>
            <div className="footer_main_screen_container">
                <Footer />
            </div>
        </div>
    )
}

export default LandingPage;