import React, { useState, useEffect } from 'react';
import "./SignInStyle.css";
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import WarningModal from '../WarningModal/warningModal';
import CustomloaderSpinner from '../LoaderSpinner/loader';
import { apiCall } from '../../Utils/APIUtils/ApiUtils';
import Global from '../../Utils/Global';
import { handlesignIn, handleconfirmSignIn, getAccessToken } from '../../Utils/APIUtils/Auth';

const SignIn = () => {
    const navigate = useNavigate();
    const [phoneNo, setPhoneNo] = useState('');
    const [otp, setOtp] = useState("");
    const [otpSent, setotpSent] = useState(false);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorNumber, setErrorNumber] = useState('');

    const openErrorModal = (message) => {
        setErrorMessage(message);
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = () => {
        setIsErrorModalOpen(false);
        setErrorMessage('');
    };

    const svgLogo = `<svg xmlns="http://www.w3.org/2000/svg" width="206" height="116" viewBox="0 0 206 116" fill="none">
    <path d="M39.2242 14.2432H23.6611V85.0817H39.2242V14.2432Z" fill="#6C63FF"/>
    <mask id="mask0_7123_4909" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="23" y="14" width="17" height="72">
      <path d="M39.2242 14.2432H23.6611V85.0817H39.2242V14.2432Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_7123_4909)">
      <path d="M39.2242 14.2432H23.6611V85.0817H39.2242V14.2432Z" fill="#6C63FF"/>
    </g>
    <path d="M73.2613 36.8691H57.6982V85.0665H73.2613V36.8691Z" fill="#FF974F"/>
    <mask id="mask1_7123_4909" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="57" y="36" width="17" height="50">
      <path d="M73.2613 36.8691H57.6982V85.0665H73.2613V36.8691Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_7123_4909)">
      <path d="M73.2613 36.8691H57.6982V85.0665H73.2613V36.8691Z" fill="#FF974F"/>
    </g>
    <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M73.2764 44.2098V36.8691H57.6982V50.3723C62.7653 49.2243 68.3602 47.3967 73.2764 44.2098Z" fill="black"/>
    <path d="M23.6611 70.461C23.6611 70.461 27.2503 65.6579 26.0589 57.2751C24.8676 48.8771 21.2633 39.286 32.0459 32.0964C42.8285 24.9068 65.5699 39.286 83.5308 17.7021C83.5308 17.7021 85.9286 32.0964 71.5569 41.6876C62.78 47.548 53.0229 47.835 45.166 48.9376C25.5613 51.6865 32.3324 61.761 23.6611 70.461Z" fill="#6C63FF"/>
    <mask id="mask2_7123_4909" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="23" y="17" width="61" height="54">
      <path d="M23.6611 70.461C23.6611 70.461 27.2503 65.6579 26.0589 57.2751C24.8676 48.8771 21.2633 39.286 32.0459 32.0964C42.8285 24.9068 65.5699 39.286 83.5308 17.7021C83.5308 17.7021 85.9286 32.0964 71.5569 41.6876C62.78 47.548 53.0229 47.835 45.166 48.9376C25.5613 51.6865 32.3324 61.761 23.6611 70.461Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_7123_4909)">
      <path d="M85.929 17.687H21.2637V70.461H85.929V17.687Z" fill="#6C63FF"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M65.5703 4.72754C70.8635 4.72754 75.1464 9.01712 75.1464 14.3187C75.1464 19.6202 70.8635 23.9098 65.5703 23.9098C60.277 23.9098 55.9941 19.6202 55.9941 14.3187C55.9941 9.01712 60.277 4.72754 65.5703 4.72754Z" fill="#FF974F"/>
    <path d="M31.4728 21.7195C35.7871 21.7195 39.2846 18.1963 39.2846 13.8502C39.2846 9.50415 35.7871 5.98096 31.4728 5.98096C27.1586 5.98096 23.6611 9.50415 23.6611 13.8502C23.6611 18.1963 27.1586 21.7195 31.4728 21.7195Z" fill="#6C63FF"/>
    <path d="M31.4728 92.3318C35.7871 92.3318 39.2846 88.8086 39.2846 84.4625C39.2846 80.1165 35.7871 76.5933 31.4728 76.5933C27.1586 76.5933 23.6611 80.1165 23.6611 84.4625C23.6611 88.8086 27.1586 92.3318 31.4728 92.3318Z" fill="#6C63FF"/>
    <path d="M65.4641 92.3318C69.7783 92.3318 73.2758 88.8086 73.2758 84.4625C73.2758 80.1165 69.7783 76.5933 65.4641 76.5933C61.1498 76.5933 57.6523 80.1165 57.6523 84.4625C57.6523 88.8086 61.1498 92.3318 65.4641 92.3318Z" fill="#FF974F"/>
    <path d="M77.9668 73.0284C77.9668 70.2493 78.4946 67.6513 79.5352 65.2045C80.5908 62.7727 82.0084 60.6279 83.818 58.8003C85.6277 56.9727 87.7541 55.5378 90.2122 54.4805C92.6703 53.4232 95.2792 52.9097 98.0541 52.9097C100.829 52.9097 103.423 53.4383 105.866 54.4805C108.294 55.5378 110.42 56.9727 112.23 58.8003C114.039 60.6279 115.457 62.7576 116.513 65.2045C117.568 67.6362 118.081 70.2493 118.081 73.0284C118.081 75.8076 117.553 78.4055 116.513 80.8524C115.457 83.2841 114.039 85.4289 112.23 87.2565C110.42 89.0842 108.309 90.519 105.866 91.5763C103.438 92.6336 100.829 93.1472 98.0541 93.1472C95.2792 93.1472 92.6703 92.6185 90.2122 91.5763C87.7541 90.519 85.6277 89.0842 83.818 87.2565C82.0084 85.4289 80.5908 83.2993 79.5352 80.8524C78.4946 78.4055 77.9668 75.8076 77.9668 73.0284ZM86.2762 73.0284C86.2762 74.6748 86.5778 76.2003 87.1961 77.6352C87.7993 79.0701 88.6438 80.3086 89.7145 81.381C90.7852 82.4534 92.0369 83.2993 93.4545 83.9034C94.8871 84.5076 96.4103 84.8248 98.0541 84.8248C99.6978 84.8248 101.206 84.5227 102.623 83.9034C104.026 83.2993 105.263 82.4534 106.333 81.381C107.404 80.3086 108.248 79.055 108.852 77.6352C109.455 76.2003 109.772 74.6748 109.772 73.0284C109.772 71.3821 109.47 69.8717 108.852 68.4519C108.248 67.0472 107.404 65.8086 106.333 64.7362C105.263 63.6638 104.026 62.818 102.623 62.2138C101.221 61.6097 99.6978 61.2925 98.0541 61.2925C96.4103 61.2925 94.8871 61.5946 93.4545 62.2138C92.0218 62.818 90.7852 63.6638 89.7145 64.7362C88.6438 65.8086 87.7993 67.0472 87.1961 68.4519C86.5929 69.8717 86.2762 71.3821 86.2762 73.0284Z" fill="#6C63FF"/>
    <path d="M136.961 52.7891C139.012 52.7891 140.973 53.1818 142.813 53.9823C144.652 54.7828 146.281 55.8703 147.653 57.2599C149.026 58.6495 150.142 60.2656 150.956 62.1083C151.77 63.951 152.178 65.9599 152.178 68.1047C152.178 70.2042 151.786 72.1677 150.986 73.9953C150.187 75.8229 149.101 77.424 147.744 78.8135C146.387 80.2031 144.773 81.2906 142.933 82.0911C141.093 82.8917 139.088 83.2844 136.946 83.2844C134.85 83.2844 132.875 82.9068 131.035 82.1516L130.16 81.774V88.9635C130.16 90.1417 129.753 91.1385 128.939 91.9542C128.124 92.7698 127.144 93.1776 126.013 93.1776C124.791 93.1776 123.781 92.7698 122.967 91.9542C122.152 91.1385 121.745 90.1417 121.745 88.9635V68.1047C121.745 66.0052 122.137 64.0417 122.906 62.2141C123.675 60.3865 124.746 58.7854 126.118 57.426C127.476 56.0667 129.104 54.9641 130.959 54.1484C132.814 53.3177 134.82 52.8797 136.961 52.7891ZM136.961 61.051C135.996 61.051 135.091 61.2323 134.247 61.5797C133.402 61.9422 132.663 62.4255 132.015 63.0599C131.366 63.6943 130.854 64.4344 130.477 65.2953C130.1 66.1562 129.904 67.0927 129.904 68.1047C129.904 69.0714 130.1 69.9927 130.477 70.8536C130.854 71.7146 131.366 72.4698 132.015 73.1193C132.663 73.7688 133.417 74.2823 134.247 74.6599C135.091 75.0375 135.981 75.2339 136.961 75.2339C137.927 75.2339 138.846 75.0375 139.706 74.6599C140.566 74.2823 141.305 73.7688 141.938 73.1193C142.571 72.4698 143.069 71.7146 143.446 70.8536C143.823 69.9927 144.019 69.0714 144.019 68.1047C143.929 66.1714 143.235 64.5401 141.938 63.1807C140.626 61.8516 138.967 61.1266 136.961 61.051Z" fill="#6C63FF"/>
    <path d="M178.132 84.6893C179.308 84.6893 180.304 85.1122 181.118 85.9429C181.932 86.7888 182.339 87.7705 182.339 88.9033C182.339 90.1268 181.932 91.1388 181.118 91.9544C180.304 92.77 179.308 93.1778 178.132 93.1778H160.126C159.04 93.1778 158.06 92.77 157.2 91.9544C156.341 91.1388 155.903 90.1117 155.903 88.9033V57.1846C155.903 56.0971 156.295 55.1002 157.095 54.2242C157.894 53.3481 158.904 52.895 160.111 52.895H178.117C179.293 52.895 180.289 53.3179 181.103 54.1487C181.917 54.9945 182.324 56.0065 182.324 57.1695C182.324 58.3929 181.917 59.4049 181.103 60.1903C180.289 60.9909 179.293 61.3836 178.117 61.3836H164.273V68.5731H173.909C175.04 68.5731 176.021 68.9961 176.865 69.8268C177.71 70.6726 178.117 71.6695 178.117 72.8476C178.117 74.0257 177.695 75.0226 176.865 75.8382C176.021 76.6539 175.04 77.0617 173.909 77.0617H164.273V84.6289H178.132V84.6893Z" fill="#6C63FF"/>
    <path d="M29.3922 106.877H28.7739V102.527H23.6163V106.877H22.998V98.041H23.6163V101.968H28.7739V98.041H29.3922V106.877Z" fill="#1A1A1A"/>
    <path d="M34.3837 106.998C33.4337 106.998 32.6796 106.711 32.1518 106.122C31.624 105.533 31.3525 104.702 31.3525 103.645C31.3525 102.603 31.6089 101.757 32.1216 101.122C32.6344 100.488 33.3281 100.171 34.2028 100.171C34.9719 100.171 35.5902 100.443 36.0275 100.987C36.4648 101.53 36.691 102.255 36.691 103.192V103.675H31.9558C31.9708 104.581 32.1669 105.261 32.5891 105.744C32.9963 106.213 33.5995 106.454 34.3686 106.454C34.7457 106.454 35.0774 106.424 35.3489 106.379C35.6354 106.333 35.9973 106.213 36.4196 106.046V106.59C36.0426 106.756 35.7108 106.862 35.3941 106.922C35.0774 106.983 34.7607 106.998 34.3837 106.998ZM34.2178 100.715C33.5845 100.715 33.0717 100.926 32.6947 101.334C32.3177 101.757 32.0915 102.346 32.0312 103.146H36.0878C36.0878 102.391 35.9219 101.787 35.5902 101.364C35.2584 100.926 34.7909 100.715 34.2178 100.715Z" fill="#1A1A1A"/>
    <path d="M42.5126 106.877L42.3617 105.835H42.3165C41.9847 106.258 41.653 106.56 41.3061 106.726C40.9593 106.892 40.5521 106.983 40.0695 106.983C39.421 106.983 38.9234 106.817 38.5615 106.484C38.1995 106.152 38.0186 105.699 38.0186 105.11C38.0186 104.476 38.29 103.977 38.8178 103.615C39.3456 103.252 40.1298 103.071 41.1402 103.056L42.3919 103.026V102.588C42.3919 101.968 42.2713 101.485 42.0149 101.168C41.7585 100.851 41.3514 100.684 40.7934 100.684C40.1901 100.684 39.5568 100.851 38.9083 101.198L38.6821 100.684C39.406 100.352 40.1147 100.171 40.8235 100.171C41.5474 100.171 42.0752 100.352 42.4371 100.73C42.7991 101.107 42.965 101.696 42.965 102.482V106.847H42.5126V106.877ZM40.0997 106.454C40.7934 106.454 41.3513 106.258 41.7585 105.85C42.1657 105.442 42.3617 104.899 42.3617 104.174V103.524L41.2156 103.569C40.2957 103.615 39.6322 103.751 39.2401 104.007C38.848 104.249 38.6519 104.627 38.6519 105.14C38.6519 105.548 38.7726 105.865 39.0289 106.092C39.2853 106.333 39.6322 106.454 40.0997 106.454Z" fill="#1A1A1A"/>
    <path d="M45.6794 106.877H45.0762V97.4673H45.6794V106.877Z" fill="#1A1A1A"/>
    <path d="M49.6005 106.469C49.9775 106.469 50.3092 106.439 50.5958 106.379V106.862C50.3092 106.953 49.9775 107.013 49.6005 107.013C49.0274 107.013 48.5901 106.862 48.3186 106.545C48.0472 106.228 47.9114 105.744 47.9114 105.08V100.835H46.9463V100.488L47.9114 100.216L48.2131 98.7358H48.5147V100.322H50.445V100.835H48.5147V104.989C48.5147 105.487 48.6051 105.865 48.7861 106.107C48.952 106.348 49.2234 106.469 49.6005 106.469Z" fill="#1A1A1A"/>
    <path d="M56.5825 106.877V102.618C56.5825 101.953 56.4468 101.47 56.1603 101.183C55.8888 100.881 55.4515 100.73 54.8633 100.73C54.0792 100.73 53.5061 100.926 53.1442 101.319C52.7822 101.712 52.6013 102.361 52.6013 103.252V106.877H51.998V97.4673H52.6013V100.458L52.5711 101.304H52.6163C52.8576 100.911 53.1743 100.624 53.5513 100.443C53.9284 100.262 54.3959 100.171 54.9387 100.171C56.4317 100.171 57.1707 100.971 57.1707 102.572V106.877H56.5825Z" fill="#1A1A1A"/>
    <path d="M70.0946 102.452C70.0946 103.856 69.7477 104.959 69.0389 105.775C68.3302 106.59 67.365 106.998 66.1435 106.998C64.922 106.998 63.9568 106.59 63.248 105.775C62.5392 104.959 62.1924 103.841 62.1924 102.437C62.1924 101.032 62.5392 99.9292 63.248 99.1136C63.9568 98.298 64.922 97.9053 66.1435 97.9053C67.3801 97.9053 68.3452 98.3131 69.0389 99.1287C69.7477 99.9292 70.0946 101.032 70.0946 102.452ZM62.871 102.452C62.871 103.72 63.1575 104.702 63.7155 105.382C64.2735 106.077 65.0878 106.424 66.1435 106.424C67.1991 106.424 68.0135 106.077 68.5865 105.397C69.1445 104.717 69.431 103.735 69.431 102.452C69.431 101.183 69.1445 100.201 68.5865 99.5214C68.0285 98.8417 67.2142 98.5094 66.1586 98.5094C65.1029 98.5094 64.3037 98.8568 63.7306 99.5365C63.1575 100.216 62.871 101.183 62.871 102.452Z" fill="#1A1A1A"/>
    <path d="M74.4073 100.82H72.854V106.877H72.2508V100.82H71.0293V100.473L72.2508 100.246V99.7327C72.2508 98.9322 72.4016 98.3281 72.6882 97.9505C72.9898 97.5729 73.4723 97.3916 74.1359 97.3916C74.4978 97.3916 74.8597 97.452 75.2217 97.5578L75.086 98.0713C74.7693 97.9656 74.4375 97.9202 74.1208 97.9202C73.6533 97.9202 73.3215 98.0562 73.1255 98.3281C72.9294 98.5999 72.839 99.0531 72.839 99.6723V100.277H74.3923V100.82H74.4073Z" fill="#1A1A1A"/>
    <path d="M84.3455 100.579C84.3455 101.439 84.0589 102.089 83.4708 102.542C82.8826 102.995 82.0683 103.222 81.0127 103.222H79.6554V106.877H79.0371V98.041H81.1936C83.3049 98.041 84.3455 98.8869 84.3455 100.579ZM79.6554 102.693H80.8619C81.8572 102.693 82.581 102.527 83.0184 102.195C83.4557 101.862 83.6819 101.334 83.6819 100.594C83.6819 99.9139 83.4708 99.4004 83.0485 99.0832C82.6263 98.7509 81.9778 98.5848 81.1031 98.5848H79.6403V102.693H79.6554Z" fill="#1A1A1A"/>
    <path d="M88.7792 106.998C87.8292 106.998 87.0751 106.711 86.5473 106.122C86.0195 105.533 85.748 104.702 85.748 103.645C85.748 102.603 86.0044 101.757 86.5172 101.122C87.0299 100.488 87.7236 100.171 88.5983 100.171C89.3674 100.171 89.9857 100.443 90.423 100.987C90.8603 101.53 91.0866 102.255 91.0866 103.192V103.675H86.3513C86.3663 104.581 86.5624 105.261 86.9847 105.744C87.3918 106.213 87.995 106.454 88.7642 106.454C89.1412 106.454 89.4729 106.424 89.7444 106.379C90.0309 106.333 90.3929 106.213 90.8151 106.046V106.59C90.4381 106.756 90.1063 106.862 89.7896 106.922C89.4729 106.983 89.1563 106.998 88.7792 106.998ZM88.5983 100.715C87.9649 100.715 87.4522 100.926 87.0751 101.334C86.6981 101.757 86.4719 102.346 86.4116 103.146H90.4683C90.4683 102.391 90.3024 101.787 89.9706 101.364C89.6388 100.926 89.1864 100.715 88.5983 100.715Z" fill="#1A1A1A"/>
    <path d="M98.3406 103.584C98.3406 104.657 98.0842 105.488 97.5564 106.092C97.0437 106.696 96.3198 106.998 95.3999 106.998C94.8268 106.998 94.3141 106.862 93.8768 106.575C93.4394 106.303 93.1077 105.895 92.8664 105.382C92.6402 104.868 92.5195 104.264 92.5195 103.584C92.5195 102.512 92.7759 101.681 93.3037 101.077C93.8315 100.473 94.5403 100.186 95.4452 100.186C96.35 100.186 97.0588 100.488 97.5715 101.092C98.0842 101.696 98.3406 102.527 98.3406 103.584ZM93.1529 103.584C93.1529 104.491 93.349 105.186 93.7411 105.699C94.1331 106.213 94.6911 106.454 95.4301 106.454C96.169 106.454 96.7119 106.197 97.1191 105.699C97.5112 105.201 97.7072 104.491 97.7072 103.584C97.7072 102.678 97.5112 101.968 97.104 101.47C96.6968 100.971 96.1389 100.715 95.415 100.715C94.6911 100.715 94.1331 100.956 93.7411 101.455C93.349 101.968 93.1529 102.678 93.1529 103.584Z" fill="#1A1A1A"/>
    <path d="M102.955 106.998C101.944 106.998 101.205 106.62 100.738 105.865H100.693L100.708 106.379C100.723 106.681 100.738 106.998 100.738 107.361V109.868H100.135V100.322H100.648L100.768 101.258H100.798C101.251 100.549 101.975 100.201 102.955 100.201C103.845 100.201 104.523 100.488 104.976 101.077C105.443 101.666 105.669 102.512 105.669 103.615C105.669 104.702 105.428 105.533 104.93 106.122C104.478 106.696 103.814 106.998 102.955 106.998ZM102.955 106.469C103.633 106.469 104.146 106.213 104.523 105.714C104.885 105.216 105.081 104.521 105.081 103.615C105.081 101.681 104.387 100.715 102.985 100.715C102.216 100.715 101.658 100.926 101.296 101.349C100.934 101.772 100.753 102.452 100.753 103.403V103.6C100.753 104.627 100.919 105.367 101.266 105.805C101.613 106.243 102.171 106.469 102.955 106.469Z" fill="#1A1A1A"/>
    <path d="M108.113 106.877H107.51V97.4673H108.113V106.877Z" fill="#1A1A1A"/>
    <path d="M112.969 106.998C112.019 106.998 111.265 106.711 110.737 106.122C110.209 105.533 109.938 104.702 109.938 103.645C109.938 102.603 110.194 101.757 110.707 101.122C111.219 100.488 111.913 100.171 112.788 100.171C113.557 100.171 114.175 100.443 114.612 100.987C115.05 101.53 115.276 102.255 115.276 103.192V103.675H110.541C110.556 104.581 110.752 105.261 111.174 105.744C111.581 106.213 112.184 106.454 112.954 106.454C113.331 106.454 113.662 106.424 113.934 106.379C114.22 106.333 114.582 106.213 115.005 106.046V106.59C114.628 106.756 114.296 106.862 113.979 106.922C113.662 106.983 113.346 106.998 112.969 106.998ZM112.788 100.715C112.154 100.715 111.642 100.926 111.265 101.334C110.888 101.757 110.661 102.346 110.601 103.146H114.658C114.658 102.391 114.492 101.787 114.16 101.364C113.828 100.926 113.376 100.715 112.788 100.715Z" fill="#1A1A1A"/>
    <path d="M125.29 106.877H120.464V98.041H125.29V98.615H121.082V101.953H125.048V102.527H121.082V106.318H125.29V106.877Z" fill="#1A1A1A"/>
    <path d="M128.576 106.877L126.043 100.307H126.661L128.365 104.777C128.592 105.351 128.757 105.85 128.878 106.273H128.908C129.074 105.729 129.24 105.215 129.421 104.762L131.125 100.307H131.743L129.21 106.877H128.576Z" fill="#1A1A1A"/>
    <path d="M135.453 106.998C134.503 106.998 133.749 106.711 133.221 106.122C132.693 105.533 132.422 104.702 132.422 103.645C132.422 102.603 132.678 101.757 133.191 101.122C133.704 100.488 134.397 100.171 135.272 100.171C136.041 100.171 136.66 100.443 137.097 100.987C137.534 101.53 137.76 102.255 137.76 103.192V103.675H133.025C133.04 104.581 133.236 105.261 133.658 105.744C134.066 106.213 134.669 106.454 135.438 106.454C135.815 106.454 136.147 106.424 136.418 106.379C136.705 106.333 137.067 106.213 137.489 106.046V106.59C137.112 106.756 136.78 106.862 136.463 106.922C136.147 106.983 135.83 106.998 135.453 106.998ZM135.287 100.715C134.654 100.715 134.141 100.926 133.764 101.334C133.387 101.757 133.161 102.346 133.1 103.146H137.157C137.157 102.391 136.991 101.787 136.66 101.364C136.328 100.926 135.86 100.715 135.287 100.715Z" fill="#1A1A1A"/>
    <path d="M142.18 100.171C142.451 100.171 142.753 100.201 143.07 100.262L142.949 100.835C142.677 100.76 142.391 100.73 142.104 100.73C141.546 100.73 141.079 100.971 140.732 101.44C140.37 101.908 140.189 102.512 140.189 103.237V106.862H139.586V100.292H140.099L140.159 101.47H140.204C140.476 100.987 140.762 100.639 141.064 100.458C141.381 100.277 141.742 100.171 142.18 100.171Z" fill="#1A1A1A"/>
    <path d="M143.311 100.307H143.929L145.316 103.992C145.739 105.125 146.01 105.895 146.116 106.288H146.146C146.312 105.774 146.583 104.989 146.975 103.962L148.363 100.307H148.981L146.04 107.949C145.799 108.569 145.603 108.992 145.437 109.203C145.271 109.414 145.09 109.581 144.879 109.686C144.668 109.792 144.396 109.852 144.095 109.852C143.869 109.852 143.612 109.807 143.326 109.732V109.203C143.552 109.263 143.808 109.294 144.08 109.294C144.291 109.294 144.472 109.248 144.623 109.143C144.773 109.037 144.924 108.901 145.045 108.689C145.165 108.493 145.316 108.161 145.482 107.723C145.648 107.285 145.754 106.998 145.799 106.862L143.311 100.307Z" fill="#1A1A1A"/>
    <path d="M155.33 106.877L153.897 102.482C153.806 102.18 153.686 101.742 153.535 101.168H153.505L153.384 101.621L153.113 102.497L151.65 106.877H151.062L149.177 100.307H149.81L150.866 104.113C151.107 105.049 151.273 105.744 151.348 106.197H151.379C151.62 105.261 151.786 104.626 151.891 104.309L153.248 100.307H153.791L155.073 104.309C155.36 105.261 155.541 105.88 155.601 106.197H155.631C155.661 105.94 155.827 105.231 156.114 104.098L157.109 100.322H157.712L155.933 106.892H155.33V106.877Z" fill="#1A1A1A"/>
    <path d="M163.609 106.877V102.618C163.609 101.953 163.473 101.47 163.187 101.183C162.915 100.881 162.478 100.73 161.89 100.73C161.106 100.73 160.532 100.926 160.171 101.319C159.809 101.712 159.628 102.361 159.628 103.252V106.877H159.024V97.4673H159.628V100.458L159.597 101.304H159.643C159.884 100.911 160.201 100.624 160.578 100.443C160.955 100.262 161.422 100.171 161.965 100.171C163.458 100.171 164.197 100.971 164.197 102.572V106.877H163.609Z" fill="#1A1A1A"/>
    <path d="M168.977 106.998C168.027 106.998 167.273 106.711 166.746 106.122C166.218 105.533 165.946 104.702 165.946 103.645C165.946 102.603 166.203 101.757 166.715 101.122C167.228 100.488 167.922 100.171 168.797 100.171C169.566 100.171 170.184 100.443 170.621 100.987C171.059 101.53 171.285 102.255 171.285 103.192V103.675H166.55C166.565 104.581 166.761 105.261 167.183 105.744C167.59 106.213 168.193 106.454 168.962 106.454C169.339 106.454 169.671 106.424 169.943 106.379C170.229 106.333 170.591 106.213 171.013 106.046V106.59C170.636 106.756 170.305 106.862 169.988 106.922C169.671 106.983 169.354 106.998 168.977 106.998ZM168.797 100.715C168.163 100.715 167.65 100.926 167.273 101.334C166.896 101.757 166.67 102.346 166.61 103.146H170.666C170.666 102.391 170.501 101.787 170.169 101.364C169.837 100.926 169.385 100.715 168.797 100.715Z" fill="#1A1A1A"/>
    <path d="M175.689 100.171C175.96 100.171 176.262 100.201 176.578 100.262L176.458 100.835C176.186 100.76 175.9 100.73 175.613 100.73C175.055 100.73 174.588 100.971 174.241 101.44C173.879 101.908 173.698 102.512 173.698 103.237V106.862H173.095V100.292H173.607L173.668 101.47H173.713C173.984 100.987 174.271 100.639 174.573 100.458C174.889 100.277 175.251 100.171 175.689 100.171Z" fill="#1A1A1A"/>
    <path d="M180.574 106.998C179.624 106.998 178.87 106.711 178.342 106.122C177.814 105.533 177.543 104.702 177.543 103.645C177.543 102.603 177.799 101.757 178.312 101.122C178.825 100.488 179.519 100.171 180.393 100.171C181.162 100.171 181.781 100.443 182.218 100.987C182.655 101.53 182.881 102.255 182.881 103.192V103.675H178.146C178.161 104.581 178.357 105.261 178.78 105.744C179.187 106.213 179.79 106.454 180.559 106.454C180.936 106.454 181.268 106.424 181.539 106.379C181.826 106.333 182.188 106.213 182.61 106.046V106.59C182.233 106.756 181.901 106.862 181.585 106.922C181.268 106.983 180.951 106.998 180.574 106.998ZM180.393 100.715C179.76 100.715 179.247 100.926 178.87 101.334C178.493 101.757 178.267 102.346 178.206 103.146H182.263C182.263 102.391 182.097 101.787 181.766 101.364C181.434 100.926 180.981 100.715 180.393 100.715Z" fill="#1A1A1A"/>
  </svg>`;

    const msgIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M2 12H5.88197C6.56717 12 7.19357 12.3871 7.5 13C7.80643 13.6129 8.43283 14 9.11803 14H14.882C15.5672 14 16.1936 13.6129 16.5 13C16.8064 12.3871 17.4328 12 18.118 12H22M2 12V8.8C2 7.11984 2 6.27976 2.32698 5.63803C2.6146 5.07354 3.07354 4.6146 3.63803 4.32698C4.27976 4 5.11984 4 6.8 4H17.2C18.8802 4 19.7202 4 20.362 4.32698C20.9265 4.6146 21.3854 5.07354 21.673 5.63803C22 6.27976 22 7.11984 22 8.8V12M2 12V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V12" stroke="#86848E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

    const checkCircle = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#86848E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

    const someData = {
        id: "1",
        firstName: "Vipul",
        lastName: "Bhoir",
        status: "Active",
        nurseData: {
            total: "100",
            approved: "70",
            pending: "20",
            rejected: "10"
        }
    };

    const handlePhoneNoChange = (e) => {
        setPhoneNo(e.target.value);
    }

    const handlePhoneNoSubmit = async () => {

        const phoneRegex = /^[5-9]\d{9}$/;
        if (!phoneRegex.test(phoneNo)) {
            openErrorModal("Please enter a valid phone number");
        }
        else if (phoneNo.length === 10) {
            try {
                setIsLoading(true);
                await handlesignIn(phoneNo);
                setotpSent(true);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error during sign-in:', error);
                openErrorModal(error.msg || "Something went wrong, please try again");
            }
        }
    };

    const [counter, setCounter] = useState(60);
    const [isResendEnabled, setResendEnabled] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (counter > 0) {
                setCounter((prevCounter) => prevCounter - 1);
            } else {
                setResendEnabled(true);
                clearInterval(intervalId);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [counter]);

    const handleResendClick = async () => {
        try {
            await handlesignIn(phoneNo);
            setOtp('');
            setCounter(60);
            setResendEnabled(false);
        } catch (error) {
            console.error('Error during resend otp:', error);
            openErrorModal("Something went wrong, please try again");
        }
    };

    const resendButtonStyle = {
        padding: '10px',
        color: isResendEnabled ? 'var(--primary-button-color)' : 'gray',
        border: 'none',
        borderRadius: '4px',
        cursor: isResendEnabled ? 'pointer' : 'not-allowed',
    };

    const handleSignIn = async () => {
        if (!otp.trim()) {
            openErrorModal("Please Enter Otp");
        }
        else if (otp.length < 0 || otp.length > 6) {
            openErrorModal("Please Enter Valid Otp");
        }
        else if (!/^\d+$/.test(otp)) {
            openErrorModal("Please Enter Valid Otp");
        }
        else {
            try {
                if (Global.auth.isRegistered) {
                    setIsLoading(true);
                    await handleconfirmSignIn(otp);
                    await getAccessToken();
                    localStorage.setItem('authToken', Global.authToken);
                    sessionStorage.setItem('userData', JSON.stringify(someData));
                    sessionStorage.setItem('authToken', Global.authToken);
                    await handleVerifyAdminUser();
                    setIsLoading(false);

                } else {
                    setIsLoading(false);
                    openErrorModal("Something went wrong");
                }
            } catch (error) {
                setIsLoading(false);
                openErrorModal(error.msg || "Something went wrong, please try again");
            }
        }
    };

    const handleAction = () => {
        if (otpSent) {
            handleSignIn()
        } else {
            handlePhoneNoSubmit()
        }

    }

    const handleVerifyAdminUser = async () => {
        try {
            const body = {
                "mobileNumber": phoneNo.toString(),
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            const response = await apiCall('registration/verifyadminuser', body);
            navigate('/dashboard', { state: { response } });
        } catch (error) {
            openErrorModal(error.msg || "Something went wrong, please try again");
        }
    }

    const handleChangeNumber = () => {
        setCounter(60);
        setotpSent(false);
    }

    return (
        <div className='Sign-container'>
            <div className="signin-main-container">
                <div className="content-container">
                    <div className='svgLogoImg' dangerouslySetInnerHTML={{ __html: svgLogo }} />
                    <div className="headerTextContainer">
                        <h1>Admin  Portal</h1>
                        <p>Sign In</p>
                    </div>
                    {otpSent ?
                        <div className="Otp_container" >
                            <h1>Enter Verification Code</h1>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                containerStyle={{ display: 'flex', alignItems: "center", justifyContent: "center", }}
                                inputStyle="InputStyling"
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus
                            />
                        </div>
                        :
                        <><div className="text_input_container">
                            <div className='msgIcon' dangerouslySetInnerHTML={{ __html: msgIcon }} />
                            <div className="verticle_line"></div>
                            <div className="phoneNo_container" >
                                <p>Mobile Number</p>
                                <input
                                    type="tel"
                                    value={phoneNo}
                                    onChange={(e) => handlePhoneNoChange(e)}
                                    placeholder="Enter Mobile Number"
                                    maxLength={10}
                                    id="phone"
                                    autoComplete="tel"
                                />
                            </div>
                            <div className='checkCircle' dangerouslySetInnerHTML={{ __html: checkCircle }} />
                        </div>
                            <div className='numberError'>
                                <p>{errorNumber}</p>
                            </div>
                        </>
                    }
                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "flex-start", width: "52%" }}>
                        {otpSent && (
                            <div className="resend-otp-container">
                                <button
                                    onClick={handleChangeNumber}
                                    style={{
                                        padding: '10px',
                                        color: 'var(--primary-button-color)',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        background: "white"
                                    }}
                                >
                                    Change Number
                                </button>
                            </div>
                        )}
                        {otpSent && (
                            <div className="resend-otp-container">
                                <button
                                    onClick={handleResendClick}
                                    disabled={!isResendEnabled}
                                    style={resendButtonStyle}
                                >
                                    Resend Otp
                                </button>
                                <div className="resend-otp-button" style={{ color: isResendEnabled ? 'gray' : 'var(--primary-button-color)' }} >
                                    {Math.floor(counter / 60)}:{counter % 60 < 10 ? '0' + counter % 60 : counter % 60}
                                </div>
                            </div>
                        )}
                    </div>
                    <div onClick={() => handleAction()} className="sign-in-button">
                        <span>{otpSent ? "Submit" : "Send OTP"}</span>
                    </div>

                </div>

            </div>
            <WarningModal
                isOpen={isErrorModalOpen}
                errorMessage={errorMessage}
                onClose={closeErrorModal}
            />
            {isLoading ? <CustomloaderSpinner /> : null}
        </div>
    )
}

export default SignIn;