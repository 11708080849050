// NavbarModal.jsx

import React, { useEffect } from 'react';
import '../NavbarModal/NavbarModalStyle.css';
import '../../App.css'

const NavbarModal = ({ isOpen, onClose, items }) => {
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768 && isOpen) {
                onClose();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen, onClose]);


    const sideBar_log = `<svg xmlns="http://www.w3.org/2000/svg" width="63" height="40" viewBox="0 0 63 40" fill="none">
  <path d="M6.3939 3.61279H0.261475V30.5086H6.3939V3.61279Z" fill="#6C63FF"/>
  <mask id="mask0_9_461" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="3" width="7" height="28">
    <path d="M6.3939 3.61279H0.261475V30.5086H6.3939V3.61279Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_9_461)">
    <path d="M6.3939 3.61279H0.261475V30.5086H6.3939V3.61279Z" fill="#6C63FF"/>
  </g>
  <path d="M19.8055 12.2034H13.6731V30.5028H19.8055V12.2034Z" fill="#FF974F"/>
  <mask id="mask1_9_461" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="13" y="12" width="7" height="19">
    <path d="M19.8055 12.2034H13.6731V30.5028H19.8055V12.2034Z" fill="white"/>
  </mask>
  <g mask="url(#mask1_9_461)">
    <path d="M19.8055 12.2034H13.6731V30.5028H19.8055V12.2034Z" fill="#FF974F"/>
  </g>
  <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M19.8115 14.9904V12.2034H13.6731V17.3302C15.6697 16.8944 17.8743 16.2005 19.8115 14.9904Z" fill="black"/>
  <path d="M0.261475 24.9574C0.261475 24.9574 1.67574 23.1337 1.2063 19.951C0.736857 16.7625 -0.683346 13.1209 3.56538 10.3912C7.8141 7.66148 16.7751 13.1209 23.8523 4.92603C23.8523 4.92603 24.7971 10.3912 19.1341 14.0327C15.6757 16.2578 11.8311 16.3668 8.73516 16.7854C1.0102 17.8291 3.67828 21.6542 0.261475 24.9574Z" fill="#6C63FF"/>
  <mask id="mask2_9_461" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="21">
    <path d="M0.261475 24.9574C0.261475 24.9574 1.67574 23.1337 1.2063 19.951C0.736857 16.7625 -0.683346 13.1209 3.56538 10.3912C7.8141 7.66148 16.7751 13.1209 23.8523 4.92603C23.8523 4.92603 24.7971 10.3912 19.1341 14.0327C15.6757 16.2578 11.8311 16.3668 8.73516 16.7854C1.0102 17.8291 3.67828 21.6542 0.261475 24.9574Z" fill="white"/>
  </mask>
  <g mask="url(#mask2_9_461)">
    <path d="M24.7971 4.92017H-0.68335V24.9573H24.7971V4.92017Z" fill="#6C63FF"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7751 0C18.8608 0 20.5484 1.62866 20.5484 3.64154C20.5484 5.65443 18.8608 7.28309 16.7751 7.28309C14.6893 7.28309 13.0017 5.65443 13.0017 3.64154C13.0017 1.62866 14.6893 0 16.7751 0Z" fill="#FF974F"/>
  <path d="M3.33957 6.45165C5.03956 6.45165 6.41767 5.11397 6.41767 3.46386C6.41767 1.81375 5.03956 0.476074 3.33957 0.476074C1.63959 0.476074 0.261475 1.81375 0.261475 3.46386C0.261475 5.11397 1.63959 6.45165 3.33957 6.45165Z" fill="#6C63FF"/>
  <path d="M3.33957 33.2612C5.03956 33.2612 6.41767 31.9235 6.41767 30.2734C6.41767 28.6233 5.03956 27.2856 3.33957 27.2856C1.63959 27.2856 0.261475 28.6233 0.261475 30.2734C0.261475 31.9235 1.63959 33.2612 3.33957 33.2612Z" fill="#6C63FF"/>
  <path d="M16.7336 33.2612C18.4336 33.2612 19.8117 31.9235 19.8117 30.2734C19.8117 28.6233 18.4336 27.2856 16.7336 27.2856C15.0336 27.2856 13.6555 28.6233 13.6555 30.2734C13.6555 31.9235 15.0336 33.2612 16.7336 33.2612Z" fill="#FF974F"/>
  <path d="M21.6597 25.9326C21.6597 24.8774 21.8676 23.891 22.2777 22.962C22.6936 22.0387 23.2522 21.2244 23.9653 20.5305C24.6783 19.8366 25.5162 19.2918 26.4848 18.8904C27.4534 18.4889 28.4814 18.2939 29.5748 18.2939C30.6682 18.2939 31.6902 18.4947 32.6529 18.8904C33.6096 19.2918 34.4474 19.8366 35.1605 20.5305C35.8736 21.2244 36.4322 22.033 36.8481 22.962C37.2641 23.8853 37.4661 24.8774 37.4661 25.9326C37.4661 26.9878 37.2581 27.9741 36.8481 28.9032C36.4322 29.8265 35.8736 30.6408 35.1605 31.3347C34.4474 32.0286 33.6155 32.5734 32.6529 32.9748C31.6962 33.3762 30.6682 33.5712 29.5748 33.5712C28.4814 33.5712 27.4534 33.3705 26.4848 32.9748C25.5162 32.5734 24.6783 32.0286 23.9653 31.3347C23.2522 30.6408 22.6936 29.8322 22.2777 28.9032C21.8676 27.9741 21.6597 26.9878 21.6597 25.9326ZM24.9339 25.9326C24.9339 26.5577 25.0527 27.1369 25.2963 27.6817C25.534 28.2265 25.8668 28.6967 26.2887 29.1039C26.7106 29.511 27.2038 29.8322 27.7624 30.0616C28.3269 30.291 28.9271 30.4114 29.5748 30.4114C30.2225 30.4114 30.8167 30.2967 31.3753 30.0616C31.9279 29.8322 32.4152 29.511 32.8371 29.1039C33.259 28.6967 33.5918 28.2207 33.8294 27.6817C34.0671 27.1369 34.1919 26.5577 34.1919 25.9326C34.1919 25.3075 34.0731 24.734 33.8294 24.195C33.5918 23.6616 33.259 23.1914 32.8371 22.7842C32.4152 22.3771 31.9279 22.0559 31.3753 21.8265C30.8227 21.5971 30.2225 21.4767 29.5748 21.4767C28.9271 21.4767 28.3269 21.5914 27.7624 21.8265C27.1979 22.0559 26.7106 22.3771 26.2887 22.7842C25.8668 23.1914 25.534 23.6616 25.2963 24.195C25.0586 24.734 24.9339 25.3075 24.9339 25.9326Z" fill="#6C63FF"/>
  <path d="M44.9059 18.2478C45.7141 18.2478 46.4866 18.3969 47.2115 18.7008C47.9365 19.0048 48.5782 19.4177 49.119 19.9453C49.6597 20.4729 50.0995 21.0865 50.4203 21.7861C50.7412 22.4858 50.9017 23.2485 50.9017 24.0628C50.9017 24.8599 50.7472 25.6054 50.4322 26.2993C50.1173 26.9932 49.6894 27.6011 49.1546 28.1287C48.6198 28.6563 47.984 29.0692 47.2591 29.3731C46.5341 29.6771 45.7438 29.8262 44.9 29.8262C44.074 29.8262 43.2956 29.6828 42.5706 29.3961L42.2259 29.2527V31.9824C42.2259 32.4297 42.0655 32.8082 41.7446 33.1179C41.4237 33.4276 41.0375 33.5824 40.5918 33.5824C40.1105 33.5824 39.7124 33.4276 39.3915 33.1179C39.0706 32.8082 38.9102 32.4297 38.9102 31.9824V24.0628C38.9102 23.2657 39.0647 22.5202 39.3677 21.8263C39.6708 21.1324 40.0927 20.5245 40.6334 20.0084C41.1682 19.4922 41.81 19.0736 42.5409 18.7639C43.2718 18.4485 44.0621 18.2822 44.9059 18.2478ZM44.9059 21.3847C44.5256 21.3847 44.1691 21.4535 43.8363 21.5854C43.5035 21.723 43.2124 21.9066 42.9568 22.1474C42.7013 22.3883 42.4993 22.6693 42.3507 22.9961C42.2022 23.323 42.1249 23.6786 42.1249 24.0628C42.1249 24.4298 42.2022 24.7796 42.3507 25.1065C42.4993 25.4334 42.7013 25.7201 42.9568 25.9667C43.2124 26.2133 43.5095 26.4083 43.8363 26.5517C44.1691 26.695 44.5197 26.7696 44.9059 26.7696C45.2862 26.7696 45.6487 26.695 45.9874 26.5517C46.3261 26.4083 46.6173 26.2133 46.8669 25.9667C47.1164 25.7201 47.3125 25.4334 47.4611 25.1065C47.6096 24.7796 47.6869 24.4298 47.6869 24.0628C47.6512 23.3288 47.3779 22.7094 46.8669 22.1933C46.3499 21.6886 45.6962 21.4134 44.9059 21.3847Z" fill="#6C63FF"/>
  <path d="M61.1283 30.3599C61.5918 30.3599 61.984 30.5205 62.3049 30.8359C62.6257 31.157 62.7862 31.5298 62.7862 31.9599C62.7862 32.4244 62.6257 32.8086 62.3049 33.1183C61.984 33.428 61.5918 33.5828 61.1283 33.5828H54.0332C53.6054 33.5828 53.2191 33.428 52.8804 33.1183C52.5417 32.8086 52.3694 32.4187 52.3694 31.9599V19.917C52.3694 19.5041 52.5239 19.1256 52.8388 18.793C53.1538 18.4604 53.5519 18.2883 54.0273 18.2883H61.1223C61.5858 18.2883 61.978 18.4489 62.2989 18.7643C62.6198 19.0855 62.7802 19.4697 62.7802 19.9113C62.7802 20.3758 62.6198 20.76 62.2989 21.0582C61.978 21.3621 61.5858 21.5112 61.1223 21.5112H55.6673V24.241H59.4645C59.9101 24.241 60.2964 24.4015 60.6291 24.7169C60.9619 25.0381 61.1223 25.4166 61.1223 25.8639C61.1223 26.3112 60.956 26.6897 60.6291 26.9994C60.2964 27.309 59.9101 27.4639 59.4645 27.4639H55.6673V30.337H61.1283V30.3599Z" fill="#6C63FF"/>
  <path d="M2.51952 38.7838H2.27589V37.1322H0.243634V38.7838H0V35.429H0.243634V36.92H2.27589V35.429H2.51952V38.7838Z" fill="#1A1A1A"/>
  <path d="M4.48639 38.8296C4.11203 38.8296 3.81491 38.7207 3.60693 38.497C3.39895 38.2734 3.29199 37.958 3.29199 37.5565C3.29199 37.1608 3.39301 36.8397 3.59505 36.5988C3.79709 36.358 4.07043 36.2375 4.41508 36.2375C4.71814 36.2375 4.96177 36.3408 5.1341 36.5472C5.30642 36.7537 5.39556 37.0289 5.39556 37.3845V37.568H3.52968C3.53563 37.9121 3.61288 38.1701 3.77926 38.3537C3.9397 38.5314 4.17739 38.6232 4.48045 38.6232C4.629 38.6232 4.75973 38.6117 4.86669 38.5945C4.9796 38.5773 5.12221 38.5314 5.2886 38.4684V38.6748C5.14004 38.7379 5.00931 38.778 4.88452 38.801C4.75973 38.8239 4.63495 38.8296 4.48639 38.8296ZM4.42102 36.444C4.17145 36.444 3.96941 36.5243 3.82086 36.6791C3.6723 36.8397 3.58316 37.0633 3.55939 37.3673H5.15787C5.15787 37.0806 5.0925 36.8512 4.96177 36.6906C4.83104 36.5243 4.64683 36.444 4.42102 36.444Z" fill="#1A1A1A"/>
  <path d="M7.68925 38.7838L7.62983 38.3881H7.61201C7.48128 38.5486 7.35054 38.6633 7.21387 38.7264C7.0772 38.7895 6.91676 38.8239 6.72661 38.8239C6.47109 38.8239 6.27499 38.7608 6.13238 38.6347C5.98976 38.5085 5.91846 38.3365 5.91846 38.1128C5.91846 37.8719 6.02542 37.6827 6.2334 37.5451C6.44138 37.4074 6.75037 37.3386 7.14851 37.3329L7.64172 37.3214V37.1551C7.64172 36.92 7.59418 36.7365 7.49316 36.616C7.39214 36.4956 7.2317 36.4325 7.01184 36.4325C6.77414 36.4325 6.52457 36.4956 6.26905 36.6275L6.17992 36.4325C6.46515 36.3064 6.74443 36.2375 7.02372 36.2375C7.30895 36.2375 7.51693 36.3064 7.65954 36.4497C7.80216 36.5931 7.86752 36.8168 7.86752 37.115V38.7723H7.68925V38.7838ZM6.73849 38.6232C7.01183 38.6232 7.2317 38.5486 7.39214 38.3938C7.55258 38.239 7.62983 38.0325 7.62983 37.7572V37.5107L7.17822 37.5279C6.81574 37.5451 6.55428 37.5967 6.39978 37.6942C6.24528 37.7859 6.16803 37.9293 6.16803 38.1243C6.16803 38.2791 6.21557 38.3995 6.31659 38.4856C6.41761 38.5773 6.55428 38.6232 6.73849 38.6232Z" fill="#1A1A1A"/>
  <path d="M8.93715 38.7837H8.69946V35.2109H8.93715V38.7837Z" fill="#1A1A1A"/>
  <path d="M10.4821 38.6288C10.6307 38.6288 10.7614 38.6173 10.8743 38.5944V38.7779C10.7614 38.8123 10.6307 38.8352 10.4821 38.8352C10.2563 38.8352 10.084 38.7779 9.97703 38.6575C9.87007 38.537 9.81659 38.3535 9.81659 38.1012V36.4897H9.43628V36.3579L9.81659 36.2546L9.93543 35.6926H10.0543V36.2948H10.8149V36.4897H10.0543V38.0668C10.0543 38.256 10.0899 38.3994 10.1612 38.4912C10.2266 38.5829 10.3336 38.6288 10.4821 38.6288Z" fill="#1A1A1A"/>
  <path d="M13.2335 38.7837V37.1665C13.2335 36.9141 13.18 36.7306 13.0671 36.6217C12.9601 36.507 12.7878 36.4496 12.556 36.4496C12.247 36.4496 12.0212 36.5242 11.8786 36.6733C11.736 36.8224 11.6647 37.069 11.6647 37.4073V38.7837H11.427V35.2109H11.6647V36.3464L11.6528 36.6676H11.6706C11.7657 36.5185 11.8905 36.4095 12.0391 36.3407C12.1876 36.2719 12.3718 36.2375 12.5857 36.2375C13.174 36.2375 13.4652 36.5414 13.4652 37.1493V38.7837H13.2335Z" fill="#1A1A1A"/>
  <path d="M18.5576 37.1036C18.5576 37.6369 18.4209 38.0556 18.1416 38.3652C17.8624 38.6749 17.482 38.8297 17.0007 38.8297C16.5194 38.8297 16.1391 38.6749 15.8598 38.3652C15.5805 38.0556 15.4438 37.6312 15.4438 37.0979C15.4438 36.5645 15.5805 36.1459 15.8598 35.8362C16.1391 35.5265 16.5194 35.3774 17.0007 35.3774C17.488 35.3774 17.8683 35.5323 18.1416 35.842C18.4209 36.1459 18.5576 36.5645 18.5576 37.1036ZM15.7113 37.1036C15.7113 37.5853 15.8242 37.9581 16.044 38.2161C16.2639 38.4799 16.5848 38.6118 17.0007 38.6118C17.4167 38.6118 17.7376 38.4799 17.9634 38.2219C18.1832 37.9638 18.2961 37.591 18.2961 37.1036C18.2961 36.6219 18.1832 36.2491 17.9634 35.9911C17.7435 35.733 17.4226 35.6068 17.0067 35.6068C16.5907 35.6068 16.2758 35.7387 16.05 35.9968C15.8242 36.2549 15.7113 36.6219 15.7113 37.1036Z" fill="#1A1A1A"/>
  <path d="M20.2573 36.4842H19.6453V38.7838H19.4076V36.4842H18.9263V36.3523L19.4076 36.2662V36.0713C19.4076 35.7673 19.467 35.5379 19.5799 35.3946C19.6988 35.2512 19.8889 35.1824 20.1504 35.1824C20.293 35.1824 20.4356 35.2053 20.5782 35.2455L20.5247 35.4404C20.4 35.4003 20.2692 35.3831 20.1444 35.3831C19.9602 35.3831 19.8295 35.4347 19.7522 35.5379C19.675 35.6412 19.6393 35.8132 19.6393 36.0483V36.2777H20.2514V36.4842H20.2573Z" fill="#1A1A1A"/>
  <path d="M24.1732 36.3924C24.1732 36.7193 24.0603 36.9659 23.8286 37.1379C23.5968 37.3099 23.2759 37.396 22.86 37.396H22.3252V38.7838H22.0815V35.429H22.9313C23.7632 35.429 24.1732 35.7501 24.1732 36.3924ZM22.3252 37.1952H22.8006C23.1928 37.1952 23.478 37.1322 23.6503 37.006C23.8226 36.8798 23.9118 36.6791 23.9118 36.3981C23.9118 36.1401 23.8286 35.9451 23.6622 35.8247C23.4958 35.6985 23.2403 35.6354 22.8956 35.6354H22.3192V37.1952H22.3252Z" fill="#1A1A1A"/>
  <path d="M25.9202 38.8296C25.5459 38.8296 25.2488 38.7207 25.0408 38.497C24.8328 38.2734 24.7258 37.958 24.7258 37.5565C24.7258 37.1608 24.8269 36.8397 25.0289 36.5988C25.2309 36.358 25.5043 36.2375 25.8489 36.2375C26.152 36.2375 26.3956 36.3408 26.5679 36.5472C26.7403 36.7537 26.8294 37.0289 26.8294 37.3845V37.568H24.9635C24.9695 37.9121 25.0467 38.1701 25.2131 38.3537C25.3735 38.5314 25.6112 38.6232 25.9143 38.6232C26.0628 38.6232 26.1936 38.6117 26.3005 38.5945C26.4134 38.5773 26.5561 38.5314 26.7224 38.4684V38.6748C26.5739 38.7379 26.4432 38.778 26.3184 38.801C26.1936 38.8239 26.0688 38.8296 25.9202 38.8296ZM25.8489 36.444C25.5993 36.444 25.3973 36.5243 25.2488 36.6791C25.1002 36.8397 25.0111 37.0633 24.9873 37.3673H26.5858C26.5858 37.0806 26.5204 36.8512 26.3897 36.6906C26.2589 36.5243 26.0807 36.444 25.8489 36.444Z" fill="#1A1A1A"/>
  <path d="M29.6878 37.5335C29.6878 37.9406 29.5867 38.2561 29.3788 38.4854C29.1767 38.7148 28.8915 38.8295 28.529 38.8295C28.3032 38.8295 28.1012 38.7779 27.9288 38.6689C27.7565 38.5657 27.6258 38.4109 27.5307 38.2159C27.4416 38.0209 27.394 37.7915 27.394 37.5335C27.394 37.1263 27.4951 36.8109 27.703 36.5815C27.911 36.3521 28.1903 36.2432 28.5468 36.2432C28.9034 36.2432 29.1827 36.3579 29.3847 36.5872C29.5867 36.8166 29.6878 37.132 29.6878 37.5335ZM27.6436 37.5335C27.6436 37.8776 27.7209 38.1414 27.8754 38.3363C28.0299 38.5313 28.2497 38.6231 28.5409 38.6231C28.8321 38.6231 29.046 38.5256 29.2064 38.3363C29.3609 38.1471 29.4382 37.8776 29.4382 37.5335C29.4382 37.1894 29.3609 36.9199 29.2005 36.7306C29.0401 36.5414 28.8202 36.4439 28.535 36.4439C28.2497 36.4439 28.0299 36.5356 27.8754 36.7249C27.7209 36.9199 27.6436 37.1894 27.6436 37.5335Z" fill="#1A1A1A"/>
  <path d="M31.506 38.8296C31.1078 38.8296 30.8167 38.6863 30.6325 38.3995H30.6146L30.6206 38.5945C30.6265 38.7092 30.6325 38.8296 30.6325 38.9673V39.9192H30.3948V36.2949H30.5968L30.6444 36.6505H30.6562C30.8345 36.3809 31.1197 36.249 31.506 36.249C31.8566 36.249 32.124 36.358 32.3022 36.5816C32.4865 36.8053 32.5756 37.1264 32.5756 37.5451C32.5756 37.958 32.4805 38.2734 32.2844 38.497C32.1061 38.7149 31.8447 38.8296 31.506 38.8296ZM31.506 38.6289C31.7734 38.6289 31.9754 38.5314 32.124 38.3422C32.2666 38.1529 32.3438 37.8892 32.3438 37.5451C32.3438 36.811 32.0705 36.444 31.5179 36.444C31.2148 36.444 30.9949 36.5243 30.8523 36.6849C30.7097 36.8454 30.6384 37.1035 30.6384 37.4648V37.5393C30.6384 37.9293 30.7038 38.2103 30.8404 38.3766C30.9771 38.5429 31.197 38.6289 31.506 38.6289Z" fill="#1A1A1A"/>
  <path d="M33.5382 38.7837H33.3005V35.2109H33.5382V38.7837Z" fill="#1A1A1A"/>
  <path d="M35.4517 38.8296C35.0774 38.8296 34.7802 38.7207 34.5723 38.497C34.3643 38.2734 34.2573 37.958 34.2573 37.5565C34.2573 37.1608 34.3583 36.8397 34.5604 36.5988C34.7624 36.358 35.0358 36.2375 35.3804 36.2375C35.6835 36.2375 35.9271 36.3408 36.0994 36.5472C36.2718 36.7537 36.3609 37.0289 36.3609 37.3845V37.568H34.495C34.501 37.9121 34.5782 38.1701 34.7446 38.3537C34.905 38.5314 35.1427 38.6232 35.4458 38.6232C35.5943 38.6232 35.7251 38.6117 35.832 38.5945C35.9449 38.5773 36.0875 38.5314 36.2539 38.4684V38.6748C36.1054 38.7379 35.9746 38.778 35.8499 38.801C35.7251 38.8239 35.6003 38.8296 35.4517 38.8296ZM35.3804 36.444C35.1308 36.444 34.9288 36.5243 34.7802 36.6791C34.6317 36.8397 34.5426 37.0633 34.5188 37.3673H36.1173C36.1173 37.0806 36.0519 36.8512 35.9212 36.6906C35.7904 36.5243 35.6122 36.444 35.3804 36.444Z" fill="#1A1A1A"/>
  <path d="M40.3066 38.7838H38.405V35.429H40.3066V35.6469H38.6487V36.9142H40.2115V37.1322H38.6487V38.5716H40.3066V38.7838Z" fill="#1A1A1A"/>
  <path d="M41.6018 38.7837L40.6035 36.2891H40.8472L41.5186 37.9865C41.6078 38.2045 41.6731 38.3937 41.7207 38.5543H41.7325C41.7979 38.3478 41.8633 38.1528 41.9346 37.9808L42.6061 36.2891H42.8497L41.8514 38.7837H41.6018Z" fill="#1A1A1A"/>
  <path d="M44.3116 38.8296C43.9372 38.8296 43.6401 38.7207 43.4321 38.497C43.2242 38.2734 43.1172 37.958 43.1172 37.5565C43.1172 37.1608 43.2182 36.8397 43.4202 36.5988C43.6223 36.358 43.8956 36.2375 44.2403 36.2375C44.5433 36.2375 44.787 36.3408 44.9593 36.5472C45.1316 36.7537 45.2208 37.0289 45.2208 37.3845V37.568H43.3549C43.3608 37.9121 43.4381 38.1701 43.6045 38.3537C43.7649 38.5314 44.0026 38.6232 44.3056 38.6232C44.4542 38.6232 44.5849 38.6117 44.6919 38.5945C44.8048 38.5773 44.9474 38.5314 45.1138 38.4684V38.6748C44.9652 38.7379 44.8345 38.778 44.7097 38.801C44.5849 38.8239 44.4601 38.8296 44.3116 38.8296ZM44.2462 36.444C43.9966 36.444 43.7946 36.5243 43.6461 36.6791C43.4975 36.8397 43.4084 37.0633 43.3846 37.3673H44.9831C44.9831 37.0806 44.9177 36.8512 44.787 36.6906C44.6562 36.5243 44.472 36.444 44.2462 36.444Z" fill="#1A1A1A"/>
  <path d="M46.9618 36.2375C47.0687 36.2375 47.1876 36.249 47.3124 36.272L47.2648 36.4899C47.1579 36.4612 47.045 36.4497 46.9321 36.4497C46.7122 36.4497 46.528 36.5415 46.3913 36.7193C46.2487 36.897 46.1774 37.1264 46.1774 37.4017V38.778H45.9397V36.2834H46.1417L46.1655 36.7307H46.1833C46.2903 36.5472 46.4032 36.4153 46.522 36.3465C46.6468 36.2777 46.7894 36.2375 46.9618 36.2375Z" fill="#1A1A1A"/>
  <path d="M47.4075 36.2891H47.6511L48.1978 37.6883C48.3642 38.1184 48.4711 38.4109 48.5127 38.56H48.5246C48.59 38.365 48.6969 38.0668 48.8514 37.6769L49.3981 36.2891H49.6418L48.483 39.1908C48.3879 39.426 48.3107 39.5865 48.2453 39.6668C48.18 39.7471 48.1087 39.8102 48.0255 39.8503C47.9423 39.8905 47.8353 39.9134 47.7165 39.9134C47.6273 39.9134 47.5263 39.8962 47.4134 39.8675V39.6668C47.5025 39.6897 47.6036 39.7012 47.7105 39.7012C47.7937 39.7012 47.865 39.684 47.9244 39.6439C47.9839 39.6037 48.0433 39.5521 48.0908 39.4718C48.1384 39.3973 48.1978 39.2711 48.2632 39.1048C48.3285 38.9385 48.3701 38.8295 48.3879 38.7779L47.4075 36.2891Z" fill="#1A1A1A"/>
  <path d="M52.1434 38.7837L51.5789 37.1149C51.5433 37.0002 51.4957 36.8339 51.4363 36.6159H51.4244L51.3769 36.788L51.2699 37.1206L50.6935 38.7837H50.4618L49.719 36.2891H49.9686L50.3845 37.7342C50.4796 38.0898 50.545 38.3536 50.5747 38.5256H50.5866C50.6816 38.17 50.747 37.9292 50.7886 37.8088L51.3234 36.2891H51.5373L52.0424 37.8088C52.1553 38.17 52.2266 38.4052 52.2504 38.5256H52.2623C52.2742 38.4281 52.3395 38.1586 52.4524 37.7285L52.8446 36.2948H53.0823L52.3811 38.7894H52.1434V38.7837Z" fill="#1A1A1A"/>
  <path d="M55.4058 38.7837V37.1665C55.4058 36.9141 55.3523 36.7306 55.2394 36.6217C55.1325 36.507 54.9601 36.4496 54.7284 36.4496C54.4194 36.4496 54.1936 36.5242 54.051 36.6733C53.9084 36.8224 53.8371 37.069 53.8371 37.4073V38.7837H53.5994V35.2109H53.8371V36.3464L53.8252 36.6676H53.843C53.9381 36.5185 54.0629 36.4095 54.2114 36.3407C54.36 36.2719 54.5442 36.2375 54.7581 36.2375C55.3464 36.2375 55.6376 36.5414 55.6376 37.1493V38.7837H55.4058Z" fill="#1A1A1A"/>
  <path d="M57.5213 38.8296C57.1469 38.8296 56.8498 38.7207 56.6418 38.497C56.4339 38.2734 56.3269 37.958 56.3269 37.5565C56.3269 37.1608 56.4279 36.8397 56.63 36.5988C56.832 36.358 57.1053 36.2375 57.45 36.2375C57.7531 36.2375 57.9967 36.3408 58.169 36.5472C58.3413 36.7537 58.4305 37.0289 58.4305 37.3845V37.568H56.5646C56.5705 37.9121 56.6478 38.1701 56.8142 38.3537C56.9746 38.5314 57.2123 38.6232 57.5154 38.6232C57.6639 38.6232 57.7946 38.6117 57.9016 38.5945C58.0145 38.5773 58.1571 38.5314 58.3235 38.4684V38.6748C58.175 38.7379 58.0442 38.778 57.9194 38.801C57.7946 38.8239 57.6699 38.8296 57.5213 38.8296ZM57.45 36.444C57.2004 36.444 56.9984 36.5243 56.8498 36.6791C56.7013 36.8397 56.6121 37.0633 56.5884 37.3673H58.1868C58.1868 37.0806 58.1215 36.8512 57.9907 36.6906C57.86 36.5243 57.6817 36.444 57.45 36.444Z" fill="#1A1A1A"/>
  <path d="M60.1656 36.2375C60.2726 36.2375 60.3914 36.249 60.5162 36.272L60.4687 36.4899C60.3617 36.4612 60.2488 36.4497 60.1359 36.4497C59.916 36.4497 59.7318 36.5415 59.5952 36.7193C59.4526 36.897 59.3812 37.1264 59.3812 37.4017V38.778H59.1436V36.2834H59.3456L59.3694 36.7307H59.3872C59.4941 36.5472 59.607 36.4153 59.7259 36.3465C59.8507 36.2777 59.9933 36.2375 60.1656 36.2375Z" fill="#1A1A1A"/>
  <path d="M62.0909 38.8296C61.7165 38.8296 61.4194 38.7207 61.2114 38.497C61.0034 38.2734 60.8965 37.958 60.8965 37.5565C60.8965 37.1608 60.9975 36.8397 61.1995 36.5988C61.4016 36.358 61.6749 36.2375 62.0196 36.2375C62.3226 36.2375 62.5663 36.3408 62.7386 36.5472C62.9109 36.7537 63 37.0289 63 37.3845V37.568H61.1342C61.1401 37.9121 61.2174 38.1701 61.3837 38.3537C61.5442 38.5314 61.7819 38.6232 62.0849 38.6232C62.2335 38.6232 62.3642 38.6117 62.4712 38.5945C62.5841 38.5773 62.7267 38.5314 62.8931 38.4684V38.6748C62.7445 38.7379 62.6138 38.778 62.489 38.801C62.3642 38.8239 62.2394 38.8296 62.0909 38.8296ZM62.0196 36.444C61.77 36.444 61.568 36.5243 61.4194 36.6791C61.2708 36.8397 61.1817 37.0633 61.1579 37.3673H62.7564C62.7564 37.0806 62.6911 36.8512 62.5603 36.6906C62.4296 36.5243 62.2513 36.444 62.0196 36.444Z" fill="#1A1A1A"/>
</svg>`;


    const scrollToSectionWithOffset = (sectionId, offset = 0) => {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            const offsetTop = targetElement.offsetTop;
            window.scrollTo({
                top: offsetTop - offset,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className={`modal2 ${isOpen ? 'open' : 'closed'}`} onClick={onClose}>
            <div className="modal-content2">
                <div className='Logo_inside_hamburger'>
                    <div dangerouslySetInnerHTML={{ __html: sideBar_log }} />
                </div>
                <ul className="modal-items">
                    {items.map((item) => (
                        <li key={item.id}>
                            <a onClick={() => scrollToSectionWithOffset(item.hashLinkName, 100)}
                            >{item.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default NavbarModal;
