import React from "react";
import './App.css';
import { Route, Routes } from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import MainDashboard from '../src/DashboardComponents/MainDashboard/mainDashboard';
import SignIn from "./DashboardComponents/SignIn/SignIn";
import UpdateConsent from "./pages/UpdateConsent";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProtectedRoute from "./DashboardComponents/ProtectedRoute";

function App() {
  return (
    
    <div className="app">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/dashboard" element={<ProtectedRoute element={MainDashboard} />} />
        <Route path="/updateconsent" element={<ProtectedRoute element={UpdateConsent} />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  )
}

export default App;
