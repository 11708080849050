import React from 'react';
import './SolutionCarousalStyle.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Webpack_Icon_one from '../../Assets/WebSite_Assets/first_card_img.png';
import Webpack_Icon_two from '../../Assets/WebSite_Assets/Second_card_img.png';
import Webpack_Icon_three from '../../Assets/WebSite_Assets/third_card_img.png';
import Webpack_Icon_fourth from '../../Assets/WebSite_Assets/fourth_card_img.png';
import Webpack_Icon_fifth from '../../Assets/WebSite_Assets/fifth_card_img.png';
import Webpack_Icon_six from '../../Assets/WebSite_Assets/sixth_card_img.png';
import Webpack_Icon_seven from '../../Assets/WebSite_Assets/seventh_card_img.png';


const SolutionSection = () => {

    const CarouselData = [
        {
            id: 1,
            text: "Planning and tracking of treatment",
            icon: Webpack_Icon_one
        },
        {
            id: 2,
            text: "Stay connected with care providers for better care",
            icon: Webpack_Icon_two
        },
        {
            id: 3,
            text: "Book an appointment with healthcare professionals",
            icon: Webpack_Icon_three
        },
        {
            id: 4,
            text: "Search and hire Care professionals",
            icon: Webpack_Icon_fourth
        },
        {
            id: 5,
            text: "Reminders for all-important activities",
            icon: Webpack_Icon_fifth
        },
        {
            id: 6,
            text: "Digital Documentation of everything",
            icon: Webpack_Icon_six
        },
        {
            id: 7,
            text: "HOPE Connect Community for awareness",
            icon: Webpack_Icon_seven
        }
    ];

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 0 },
            items: 3,
            slidesToSlide: 1
        }
    };

    return (
        <div className="Solution_Section_Container">
            <div className="header_Title">
                <h1>SOLUTION</h1>
            </div>

            <div className="text_below_header">
                <h1>We will serve you with healthcare services</h1>
            </div>
            <Carousel className='web_carousal_container'
                swipeable={true}
                draggable={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
            >
                {CarouselData.map((item) => (
                    <div className={`card_container card-color-${item.id}`} key={item.id} >
                        <div className="card_text_container" >
                            <h1>{item.text}</h1>
                        </div>
                        <div className="icon_container" >
                            <img src={item.icon} style={{ width: 60, height: 60 }} alt="card_icons" />
                        </div>
                    </div>
                ))
                }
            </Carousel>
            <div className="mobile_carousal_container">
                {CarouselData.map((item) => (
                    <div className={`card_container card-color-${item.id}`} key={item.id} >
                        <div className="card_text_container" >
                            <h1>{item.text}</h1>
                        </div>
                        <div className="icon_container" >
                            <img src={item.icon} style={{ width: 60, height: 60 }} alt="card_icons" />
                        </div>
                    </div>
                ))
                }
            </div>

        </div >
    )
}

export default SolutionSection;