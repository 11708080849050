import React from 'react'
import '../Banner/BannerStyle.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BannerImage from '../../Assets/WebSite_Assets/Web_Banner_Image1.png';
import BannerImageTwo from '../../Assets/WebSite_Assets/Web-Banner-Image2.png';
import BannerImageThird from '../../Assets/WebSite_Assets/Web-Banner-Image3.png';
import BannerImageFourth from '../../Assets/WebSite_Assets/Web-Banner-Image4.png';
import MobileBannerImgFirst from '../../Assets/Mobile_Assets/Mobile-Banner-Image1.png';
import MobileBannerImgSecond from '../../Assets/Mobile_Assets/Mobile-Banner-Image2.png';
import MobileBannerImgThird from '../../Assets/Mobile_Assets/Mobile-Banner-Image3.png';
import MobileBannerImgFourth from '../../Assets/Mobile_Assets/Mobile-Banner-Image4.png';

const Banner = () => {
    const CarouselData = [
        {
            id: 1,
            text: "Patient Centric platform to manage end to end treatment",
            webImg: BannerImage,
            mobileImg: MobileBannerImgFirst
        },
        {
            id: 2,
            text: "Connecting Patients with Skilled Healthcare Professionals",
            webImg: BannerImageTwo,
            mobileImg: MobileBannerImgSecond
        },
        {
            id: 3,
            text: "Patient Services offered in the convenience of their own home",
            webImg: BannerImageThird,
            mobileImg: MobileBannerImgThird
        },
        {
            id: 4,
            text: "Providing Patients with Knowledge to Boost Their Wellbeing",
            webImg: BannerImageFourth,
            mobileImg: MobileBannerImgFourth
        },

    ];

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 0 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }

    };



    return (
        <div className='Banner_Container'>
            <Carousel className='web_carousal_container'
                swipeable={true}
                draggable={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                showDots={true}
                autoPlay={true}
                autoPlaySpeed={5000}
            >
                {CarouselData.map((item) => (
                    <div className="inner_banner_container" key={item.id}>
                        <div className="header_text_container">
                            <h1 className='header_text'>{item.text}</h1>
                        </div>
                        <div className="banner_img_container">
                            <img className='banner_img' src={item.webImg} alt="BannerImage" />
                        </div>
                        <div className="mobile_img_banner_container">
                            <img className='Mobile_Banner_Image' src={item.mobileImg} alt="Mobile_Banner_Image" />
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default Banner;