import React from 'react';
import './AppInterfaceSectionstyle.css'
// import interface_banner_img from '../../Assets/WebSite_Assets/Web-Screenshots.png';
import dashboard_sc from '../../Assets/WebSite_Assets/dashboard_sc.png';
import medicine_sc from '../../Assets/WebSite_Assets/medicine_sc.png';
import quickActions_sc from '../../Assets/WebSite_Assets/quickActions_sc.png';
import temperature_sc from '../../Assets/WebSite_Assets/temperature_sc.png';

const App_interface_section = () => {
    return (
        <div className="App_interface_section_container">
            <div className="Inner_App_interface_section_container">
                <div className="interface_header_Title">
                    <h1>App Interface Showcase</h1>
                </div>
                <div className="interface_text_below_header">
                    <p>We are committed to offering you top-notch care in a location that suits your comfort.</p>
                </div>
                <div className="interface_banner_container">
                    {/* <img src={interface_banner_img} alt="interface_banner_img" /> */}
                    <div className="first_sc">
                        <img src={dashboard_sc} alt="dashboard_sc" />
                    </div>
                    <div className="second_sc">
                        <div className="quickActions">
                            <img src={quickActions_sc} alt="quickActions_sc" />
                        </div>
                        <div className="temperature">
                            <img src={temperature_sc} alt="temperature_sc" />
                        </div>
                    </div>
                    <div className="third_sc">
                        <img src={medicine_sc} alt="medicine_sc" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App_interface_section;