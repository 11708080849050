import React from 'react';
import './YourJourneySectionStyle.css';



const YourJourneySection = () => {
    return (
        <div className='Section_Two_Container'>
            <div className="text_content_container">
                <div className="Header_Title">
                    <h1>
                        “Your Journey, Your App”
                    </h1>
                </div>
                <div className="paragraph">
                    <p>Every cancer journey is unique, and our app respects that.
                        It adapts to your needs, preferences, and progress,
                        making it a truly personalized</p>
                </div>
                <div className="seperate_text">
                    <p>tool for your battle against cancer.</p>
                </div>
                <div className="paragraph">
                    <p>Download the app today and let us stand by your side,
                        providing the support you deserve as you work towards renewed health and vitality.
                        Remember, you're not alone on this journey – we are here to make each</p>
                </div>
                <div className="seperate_text_second">
                    <p>step a bit easier."</p>
                </div>
                <div className="mobile_paragraph">
                    <p>Every cancer journey is unique, and our app respects that.
                        It adapts to your needs, preferences, and progress,
                        making it a truly personalized tool for your battle against</p>
                    <p className='first_seperate_text'>cancer.</p>

                </div>
                <div className="Second_mobile_paragraph">
                    <p>Download the app today and let us stand by your side,
                        providing the support you deserve as you work towards renewed health and vitality.
                        Remember, you're not alone on this journey-we are here to make</p>
                    <p className='second_seperate_text'>each step a bit easier."</p>
                </div>
            </div>
        </div>
    )
}

export default YourJourneySection;
