import React from 'react';
import '../KeyFeatures/KeyFeaturesStyle.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import nutritionist_Custom_img from '../../Assets/WebSite_Assets/nutritionionist_img.png';
import Doctor_custom_img from '../../Assets/WebSite_Assets/Doctor_img.png';
import patient_custom_img from '../../Assets/WebSite_Assets/patient_img.png';
import nurse_custom_img from '../../Assets/WebSite_Assets/Nurse_custom_img.png';
import 'react-multi-carousel/lib/styles.css';



const KeyFeatures = () => {

    // const tabContent = [
    //     {
    //         id: 1,
    //         title: "PATIENT",
    //         image: patient_custom_img,
    //         items_left: ["Quick Registration", "Personalised dashboards", "Vitals tracker", "Medicines tracker", "Appointments", "Records and timeline"],// List of items_left for PATIENTS
    //         items_right: ["Personalised Treatment Plan", "Order Medicines", "Smart Reminders", "Paperless Documentation", "Hire Professionals", "HOPE Connect"] // List of items_left for PATIENTS
    //     },
    //     {
    //         id: 2,
    //         title: "DOCTOR",
    //         image: Doctor_custom_img,
    //         items_left: ["Personalised dashboards", "Appointments", "Patient list and records", "Treatment Plans", "Smart Reminders", "Paperless Documentation"],
    //         items_right: ["Medicines database", "Smart Reports", "Payment Management"]
    //     },
    //     {
    //         id: 3,
    //         title: "NURSE",
    //         image: nurse_custom_img,
    //         items_left: ["Easy Enrolling", "Task Creation and Management", "Convenient Plans & packages", "Personalised dashboards", "Smart Planner and schedular", "Access to patient’s activities", "Smart Reminders"],
    //         items_right: []
    //     },
    //     {
    //         id: 4,
    //         title: "NUTRITIONIST",
    //         image: nutritionist_Custom_img,
    //         items_left: ["Easy Enrolling", "Convenient Plans & packages", "Personalised dashboards", "Smart Planner and schedular", "Nutrition Database", "Smart Reminders", "Appointments", "Personalized Nutrition planner", "Patient list & records"],
    //         items_right: []
    //     },
    // ];


    // const patient_custom_img = "patient_custom_img_url";
    // const Doctor_custom_img = "doctor_custom_img_url";
    // const nurse_custom_img = "nurse_custom_img_url";
    // const nutritionist_Custom_img = "nutritionist_custom_img_url";

    const tabContent = [
        {
            id: 1,
            title: "PATIENT",
            image: patient_custom_img,
            items_left: [
                { id: 1, text: "Quick Registration" },
                { id: 2, text: "Personalised dashboards" },
                { id: 3, text: "Vitals tracker" },
                { id: 4, text: "Medicines tracker" },
                { id: 5, text: "Appointments" },
                { id: 6, text: "Records and timeline" }
            ],
            items_right: [
                { id: 1, text: "Personalised Treatment Plan" },
                { id: 2, text: "Order Medicines" },
                { id: 3, text: "Smart Reminders" },
                { id: 4, text: "Paperless Documentation" },
                { id: 5, text: "Hire Professionals" },
                { id: 6, text: "HOPE Connect" }
            ]
        },
        {
            id: 2,
            title: "DOCTOR",
            image: Doctor_custom_img,
            items_left: [
                { id: 1, text: "Personalised dashboards" },
                { id: 2, text: "Appointments" },
                { id: 3, text: "Patient list and records" },
                { id: 4, text: "Treatment Plans" },
                { id: 5, text: "Smart Reminders" },
                { id: 6, text: "Paperless Documentation" }
            ],
            items_right: [
                { id: 1, text: "Medicines database" },
                { id: 2, text: "Smart Reports" },
                { id: 3, text: "Payment Management" }
            ]
        },
        {
            id: 3,
            title: "NURSE",
            image: nurse_custom_img,
            items_left: [
                { id: 1, text: "Easy Enrolling" },
                { id: 2, text: "Task Creation and Management" },
                { id: 3, text: "Convenient Plans & packages" },
                { id: 4, text: "Personalised dashboards" },
                { id: 5, text: "Smart Planner and scheduler" },
                { id: 6, text: "Access to patient’s activities" },
                { id: 7, text: "Smart Reminders" }
            ],
            items_right: []
        },
        {
            id: 4,
            title: "NUTRITIONIST",
            image: nutritionist_Custom_img,
            items_left: [
                { id: 1, text: "Easy Enrolling" },
                { id: 2, text: "Convenient Plans & packages" },
                { id: 3, text: "Personalised dashboards" },
                { id: 4, text: "Smart Planner and scheduler" },
                { id: 5, text: "Nutrition Database" },
                { id: 6, text: "Smart Reminders" },
                { id: 7, text: "Appointments" },
                { id: 8, text: "Personalized Nutrition planner" },
                { id: 9, text: "Patient list & records" }
            ],
            items_right: []
        }
    ];




    return (
        <div className="KeyFeatures_Screen_Container">
            <div className="KeyFeatures_inner_container">
                <Tabs className="tabs_container">
                    <TabList className="tablist" >
                        {tabContent.map((tab) => (
                            <Tab className="tab_btn_container" key={tab.id} >{tab.title}</Tab>
                        ))}
                    </TabList>
                    {tabContent.map((item) => (
                        <TabPanel key={item.id}>
                            <div className="TabPanel">
                                <div className="left_container">
                                    <h2>{item.title}</h2>
                                    <h1>Key Features</h1>
                                    <div className="list_of_features_container">
                                        <div className="list_of_features_container_left">
                                            <ul>
                                                {item.items_left.map((item, index) => (
                                                    <li key={item.id}>{item.text}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="list_of_features_container_right">
                                            <ul>
                                                {item.items_right.map((item, index) => (
                                                    <li key={item.id}>{item.text}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="right_container">
                                    <img src={item.image} alt="card_image" />
                                </div>
                            </div>
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </div>
    )
}

export default KeyFeatures;