import React from 'react';
import '../HopeAppServices/HopeAppServicesStyles.css';
import patient_icon from '../../Assets/WebSite_Assets/card_img_one.png';
import care_circle_icon from '../../Assets/WebSite_Assets/card_img_two.png';
import Care_Professional_icon from '../../Assets/WebSite_Assets/card_img_three.png';
import medical_services_icon from '../../Assets/WebSite_Assets/card_img_four.png';
import Platform_icons_app_store from '../../Assets/WebSite_Assets/Platform_icons_app_store.png';
import Platform_icons_play_store from '../../Assets/WebSite_Assets/Platform_icons_play_store.png';

const HopeAppServices = () => {

    const hopeApp_card_data = [
        {
            id: 1,
            name: "Family members"
        }, {
            id: 2,
            name: "Neighbours"
        }, {
            id: 3,
            name: "In-laws"
        }, {
            id: 4,
            name: "Friends"
        }
    ]

    const care_Professionals_Data = [
        {
            id: 1,
            name: "Doctors"
        }, {
            id: 2,
            name: "Nurses"
        }, {
            id: 3,
            name: "Nutritionist"
        }, {
            id: 4,
            name: "Psychologists"
        }, {
            id: 5,
            name: "Physiotherapist"
        }, {
            id: 6,
            name: "Homecare"
        }
    ]

    const medical_services = [
        {
            id: 1,
            name: "Pharmacy"
        }, {
            id: 2,
            name: "Pathology"
        }, {
            id: 3,
            name: "Hospitals"
        }, {
            id: 4,
            name: "Clinics"
        }, {
            id: 5,
            name: "Insurance Companies"
        }
    ]

    return (
        <div className="Hope_App_Services_container">
            <div className="Inner_Services_Container">
                <div className="header_Title">
                    <h1>HOPE APP SERVICES</h1>
                </div>
                <div className="text_below_header">
                    <h1>We provide services for</h1>
                </div>
                <div className="custom_Design_container">
                    <div className="card_one_container">
                        <div className="card_header_container">
                            <div className="card_header">
                                <h1>HOPE APP</h1>
                            </div>
                            <div className="card_sub_header">
                                <p>For Patients & Loved Ones</p>
                            </div>
                        </div>
                        <div className="card_content_container">
                            <div className="purple_card_container">
                                <div className="purple_cards">
                                    <div className="patient_icon_container">
                                        <img src={patient_icon} alt="patient_icon" />
                                    </div>
                                    <h1>Patients</h1>
                                </div>
                                <div className="purple_cards">
                                    <div className="care_circle_icon_container">
                                        <img src={care_circle_icon} alt="care_circle_icon" />
                                    </div>
                                    <h1>Care Circle</h1>
                                </div>
                            </div>
                            <div className="recommendation_container_hope">
                                <ul>
                                    {
                                        hopeApp_card_data.map((item) => (
                                            <li key={item.id}>
                                                <h3>{item.name}</h3>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="platform_icons_container">
                                <div className="apple_store">
                                    <a href="https://www.apple.com/in/app-store/" target='_blank' rel='noopener noreferrer'>
                                        <img src={Platform_icons_app_store} alt="Platform_icons_app_store" />
                                    </a>
                                </div>
                                <div className="play_store">
                                    <a href="https://play.google.com/store" target='_blank' rel='noopener noreferrer'>
                                        <img src={Platform_icons_play_store} alt="Platform_icons_play_store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card_two_container">
                        <div className="card_two_header_container">
                            <div className="card_two_header">
                                <h1>HOPE BUDDY APP</h1>
                            </div>
                            <div className="card_two_sub_header">
                                <p>For Healthcare Professionals</p>
                            </div>
                        </div>
                        <div className="card_content_container">
                            <div className="purple_card_container">
                                <div className="purple_cards">
                                    <div className="Care_Professional_container">
                                        <img src={Care_Professional_icon} alt="Care_Professional_icon" />
                                    </div>
                                    <h1 className='Care_Professionals_text'>Care</h1>
                                    <h1 className='Care_Professionals_text'>Professionals</h1>
                                </div>
                                <div className="purple_cards">
                                    <div className="medical_services_container">
                                        <img src={medical_services_icon} alt="medical_services_icon" />
                                    </div>
                                    <h1 className='Medical_Services_text'>Medical Services Organization</h1>
                                </div>
                            </div>
                            <div className="recommendation_container_Professionals">
                                <div className="care_professionals">
                                    <ul>
                                        {
                                            care_Professionals_Data.map((item) => (
                                                <li key={item.id}>
                                                    <h3>{item.name}</h3>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="medical_services">
                                    <ul>
                                        {
                                            medical_services.map((item) => (
                                                <li key={item.id}>
                                                    <h3>{item.name}</h3>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="platform_icons_container">
                                <div className="apple_store">
                                    <a href="https://www.apple.com/in/app-store/" target='_blank' rel='noopener noreferrer'>
                                        <img src={Platform_icons_app_store} alt="Platform_icons_app_store" />
                                    </a>
                                </div>
                                <div className="play_store">
                                    <a href="https://play.google.com/store" target='_blank' rel='noopener noreferrer'>
                                        <img src={Platform_icons_play_store} alt="Platform_icons_play_store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HopeAppServices;